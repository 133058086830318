import React, { Fragment, useEffect, useRef, useState } from 'react';
import "../../utils/select2_4.0";
import $ from 'jquery';
import "../../assets/VagaroStyle/select2.scss";
import ReactDom from "react-dom";
import { useChatContext } from '../../Vagaroconnect/Store/ChatProvider';
import { CommonMethods } from '../../Vagaroconnect/Binding/ChatBoatEnums';
import { isAndroidIOSTabIpad } from '../../utils/Utils';
const cachingDataInfo = { pageSize: 10, cache_isSyncStarted: false, };
// import { CallBackAction, secondCallTimer, utils } from '../../utils/Utils.js';


const MultiGroupSelectEmployee = (props) => {
    const { defultSelection = [], newSelectedValues, isFromConnectApp } = props;
    const { AddCommonMethod } = useChatContext();

    let SelectionType = { Select: 1, Deselect: 2, Opening: 3 };
    const [isandroidiospro, setisandroidiospro] = useState(props.isandroidiospro ? props.isandroidiospro : false);
    const [placeHolderName, setPlaceHolderName] = useState(props.placeholder);
    const [selectedValue, setSelectedValue] = useState([]);
    const CloseSelect = useRef(false);
    let orientationQuery = window.matchMedia('(orientation: portrait)');

    const Control = { ControlID: "#" + props.ControlID }

    const resetOnClickValue = $("#resetBtnClickCustome").val();

    const OpenMultiSelect = () => {

        if (isandroidiospro === true || isAndroidIOSTabIpad()) {
            if ($("#divSortFunction").hasClass('showFilterMenu') === true || $("#divSortDirectionTrans").hasClass('showFilterMenu') === true) {
                $("#divSortFunction").removeClass("showFilterMenu");
                $("#divSortDirectionTrans").removeClass("showFilterMenu");
                $(".mainContent").removeClass('iphonescrollPrevent');
                $("body").removeClass('preventSrotBodyScroll');
            }
            $("#applyBtnClickValue").val(props.ControlID);
            // utils.CallBackGivenToMobileApp(CallBackAction.HideAllMenusIOU, GetTitleForProApp(props.ControlID), CallBackAction.HideFooter, secondCallTimer, false);
            $(".select2-search__field").attr("enterkeyhint", "Go");
            $('.select2-search__field').prop('focus', false);
            $("#applyId").css({ "display": "block", "z-index": "99999" });
            $("#applyId").addClass("Apply-btn-mob");
            $('.select2-container').addClass("withapplybutton");
            $('.select2-container').addClass("employee-multilocation-select");

            $('.select2').removeClass("employee-multilocation-select");
            if (!$("#div-select2-done-selection").is(":visible")) {                
                if (props.ControlID === "ddlEmployee") {
                    if(orientationQuery.matches === false && isAndroidIOSTabIpad()){
                        $('.select2-results').append('<button id="div-select2-done-selection" style="display: none;" class="btn-select-done select2Done vcgradientbtn vcprimary-btn"> <div> Add </div> </button>');
                    }else{
                        $('.select2-results').append('<button id="div-select2-done-selection" style="display:flex;" class="btn-select-done select2Done vcgradientbtn vcprimary-btn"> <div> Add </div> </button>');
                    }
                } else {
                    $('.select2-results').append('<button id="div-select2-done-selection" style="display: none;" class="btn-select-done select2Done vcgradientbtn vcprimary-btn"> <div> Add </div> </button>');
                }
            }
            $('#div-select2-done-selection').on('click', function () {
                CommonMethods.Actionclick("employeeSelectionDone");
                if (CommonMethods.IsElement(props.ControlID)) {
                    $(Control.ControlID).select2("close").blur();
                }
                setTimeout(() => { CommonMethods.RemoveElementbyClass(props.ControlID); CommonMethods.RemoveElementbyID(props.ControlID); CommonMethods.RemoveClassbyClass("chatActive"); }, 100);

                // $(Control.ControlID + '.expandedWidth').select2("close").blur();
                // $(Control.ControlID).select2('destroy');
                // CommonMethods.RemoveElementbyClass(props.ControlID);
                // CommonMethods.RemoveElementbyID(props.ControlID);
                // setTimeout(() => { CommonMethods.RemoveElementbyClass(props.ControlID); CommonMethods.RemoveClassbyClass("chatActive");}, 100);
            });
            if (!$("#div-select2-Close-selection").is(":visible")) {
                $('.select2-results').append('<div id="div-select2-Close-selection" style="display: none;" class="btn-select-done"> Done </div>');
            }
            $('#div-select2-Close-selection').on('click', function () {
                CloseSelect.current = true;
                $(Control.ControlID).select2("close");
            });
            $("#MultiddlEmployee").on("click", function () {
                if ($('.select2-results__option').attr('aria-selected') === 'false') {
                    $(Control.ControlID).select2("val", "");
                }
            });
        }
        if (isFromConnectApp) {
            $(".select2-search__field").attr("enterkeyhint", "Go");
            $('.select2-search__field').prop('focus', false);
        }
        $('.select2-container').addClass("vcEmployeeSelect");
        $('.select2-container').addClass(props.ControlID);
        $(' .selectall').addClass("AllCustomer");
        $('.driveFormMselect .selectnone').addClass("NoneCustomer");
        $(".NoneCustomer").on("click", function () {
            $(Control.ControlID).select2("val", "");
            setPlaceHolderName(props.placheholderFlag === true ? placeHolderName : props.placeholder)
        });
        $("select2-selection__rendered").append('<div class="select2backdrop ' + props.ControlID + '"></div>');
        $('.select2backdrop').on('click', function () {
            $(Control.ControlID + '.expandedWidth').select2("close");
        });
        if ($('#ddlEmployee').is(':visible')) {
            $('.select2-selection.select2-selection--multiple').css('display', 'none');
        }
        $("#MultiddlEmployee").on("click", function () {
            if ($('.select2-results__option').attr('aria-selected') === 'false') {
                $(Control.ControlID).select2("val", "");
            }
        });
    };

    const CloseMultiSelect = () => {
        $('.select2backdrop ').hide().remove();
        $('.select2-container').removeClass("driveFormMselect");
        window.onCkeditoerResize && window.onCkeditoerResize();
    }

    const OnChangeMultiSelect = () => {
        if (props.ControlID === "ddlsp") {
            props.onChange($('#' + props.ControlID).val().join(', '));
        } else {
            if (props.ControlID === "cboSPPayroll") {
                let _text = $('#divcboSPPayroll').find($('[class$="select2-selection__rendered"]')).text().replace("Selected", "Employees");
                $('#divcboSPPayroll').find($('[class$="select2-selection__rendered"]')).text(_text);
            } else if (props.ControlID === "cboCSPPayroll") {
                let _textClass = $('#divcboCSPPayroll').find($('[class$="select2-selection__rendered"]')).text().replace("Selected", "Employees");
                $('#divcboCSPPayroll').find($('[class$="select2-selection__rendered"]')).text(_textClass);
            } else if (props.ControlID === "cboSPPayrollCommission") {
                let _textProduct = $('#divcboSPPayrollCommission').find($('[class$="select2-selection__rendered"]')).text().replace("Selected", "Employees");
                $('#divcboSPPayrollCommission').find($('[class$="select2-selection__rendered"]')).text(_textProduct);
            }
            if (props.ControlID === "ddAppointmentSource") {
                props.onChange($('#' + props.ControlID).val().join(','));
            } else {

                let selectedItems = [];
                let tempSelectedItems = [];
                // let selectedlabel = [];
                // let selectedInitial = [];
                // let selectedProfile = [];
                let selectednew = [];

                $("#" + props.ControlID + " option:selected").each(function () {
                    if (!$(this).data('is-parent')) {
                        selectedItems.push($(this).val());
                        // selectedlabel.push(this.text);
                        // selectedInitial.push(this.dataset.avatarinital);
                        // selectedProfile.push(this.dataset.profile);
                        selectednew.push({ id: this.value, label: this.text, initial: this.dataset.avatarinital, profile: this.dataset.profile });
                    }
                    tempSelectedItems.push($(this).val());

                });

                if (selectedItems.length > 0) {
                    let removeValues = selectedItems.filter((item, index) => selectedItems.indexOf(item) === index);
                    let tempremoveValues = tempSelectedItems.filter((item, index) => tempSelectedItems.indexOf(item) === index);
                    let isChages = false;
                    if (defultSelection.length > 0) {
                        defultSelection.forEach(element => {
                            if (tempremoveValues.indexOf(element.toString()) === -1) {
                                tempremoveValues.push(element);
                                isChages = true;
                            }
                        });
                    }

                    if (isChages) {
                        $('#GroupddEmployee').val(tempremoveValues).trigger('change');
                    } else {
                        props.onChange((removeValues).join(','), selectednew);
                    }
                } else {
                    let selectedAllItems = []
                    $("#" + props.ControlID + " option").each(function () {
                        if (!$(this).data('is-parent')) {
                            selectedAllItems.push($(this).val());
                        }
                    });
                    let removeValues = selectedItems.filter((item, index) => selectedItems.indexOf(item) === index);
                    props.onChange((removeValues).join(','), selectednew);
                }
            }
        }
        if (props.ControlID === "ddlEmployee" || props.ControlID === "ddlServiceProviderDropdown" || props.ControlID === "ddlDepositMerchantDropdown" || props.ControlID === "ddlserviceproviderlistbusinesswise") {
            if ($("#" + props.ControlID + " option:selected").length == $("#" + props.ControlID + " option").length) {
                props.onChangeSpName("All");
            } else if ($("#" + props.ControlID + " option:selected").length == 0) {
                props.onChangeSpName("All");
            } else {
                let selectedName = []
                $("#" + props.ControlID + " option:selected").each(function () {
                    if (!$(this).data('is-parent')) {
                        selectedName.push($(this).text());
                    }
                });
                let changeName = selectedName.join(",")
                props.onChangeSpName(changeName);
            }
        }
    }

    const OutSideClick = () => {

        $(".Apply-btn-mob").css({ "display": "none", "z-index": "99" });
        if (props.ControlID === "ddlEmployee" || props.ControlID === "ddlsp") {
            $("#" + props.ControlID).val(selectedValue).trigger("change");
            props.onChange($('#' + props.ControlID).val().join(', '));
        } else if (props.ControlID === "ddlMultiLocation") {
            $("#" + props.ControlID).val(selectedValue).trigger("change");
            props.onChange($('#' + props.ControlID).val().join(','));
        }
        else {
            $("#" + props.ControlID).val(selectedValue).trigger("change");
            props.onChange($('#' + props.ControlID).val().join(','));
        }
    }

    const ApplyBtnClick = () => {

        $(".Apply-btn-mob").css({ "display": "none", "z-index": "99" });
        if (props.ControlID === "ddlMultiLocation") {
            setSelectedValue($('#' + props.ControlID).val())
        } else {
            setSelectedValue($('#' + props.ControlID).val())
        }
        if (props.ControlID === "ddlEmployee" || props.ControlID === "ddlsp") {
            let selectedItems = []
            $("#" + props.ControlID + " option:selected").each(function () {
                if (!$(this).data('is-parent')) {
                    selectedItems.push($(this).val());
                }
            });

            if (selectedItems.length > 0) {
                let removeValues = selectedItems.filter((item, index) => selectedItems.indexOf(item) === index);
                props.onChange((removeValues).join(','));
            } else {
                let selectedAllItems = []
                $("#" + props.ControlID + " option").each(function () {
                    if (!$(this).data('is-parent')) {
                        selectedAllItems.push($(this).val());
                    }
                });
                let removeValues = selectedItems.filter((item, index) => selectedItems.indexOf(item) === index);
                props.onChange((removeValues).join(','));
            }
        } else {
            props.onChange($('#' + props.ControlID).val().join(','));
        }
    };
    function connectWindowSize() {
        if (isandroidiospro === false) {
            AppplyMultiGruoupSelect();
        }
    }
    window.onresize = connectWindowSize;

    window.OnResizeSelectEmployee = () => {
        setTimeout(() => {
            connectWindowSize()
        }, 400);
    }


    const updateCount = (Count) => {
        if ($('.load-more').is(':visible')) {
            ReactDom.render(
                <span>Items <b>1 - {$('.select2-results__options').find('li').length - 1}</b>  out of <b>{Count}</b> </span>,
                document.getElementById("div-select2-results-count")
            )
        }
        else if (Count === 0) {
            ReactDom.render(
                <span>No Matches</span>,
                document.getElementById("div-select2-results-count")
            )
        }
        else {
            ReactDom.render(
                <span>Items <b>1 - {$('.select2-results__options').find('li').length}</b> out of <b>{$('.select2-results__options').find('li').length}</b></span>,
                document.getElementById("div-select2-results-count")
            )
        }
    }

    useEffect(() => {
        if (props.ControlID != null && props.ControlID !== undefined && (props.ControlID === "ddlEmployee" || props.ControlID === "ddlServiceProviderDropdown" || props.ControlID === "ddlCheckedOutByDropdown")) {
            $("select#ddlEmployee").val('').trigger('change');
            if (isandroidiospro) {
                setSelectedValue([]);
                AppplyMultiGruoupSelect();
            }
        } else {
            if (isandroidiospro) {
                AppplyMultiGruoupSelect();
            }
        }
        $(Control.ControlID).on("change", function (e) {
            OnChangeMultiSelect()
        })
        $(Control.ControlID + '.expandedWidth').on('select2:open', function (e) {
            OpenMultiSelect()
            $('.select2-search__field').on('dragover drop', function (e) {
                e.preventDefault(); // Prevent the default behavior of dragover and drop events
            });

        })
        $(Control.ControlID + '.expandedWidth').on('select2:close', function (e) {
            if (props.ControlID === "GroupddEmployee" && CloseSelect.current === false && (isandroidiospro || isAndroidIOSTabIpad())) {
                $(Control.ControlID + '.expandedWidth').select2("open");
            }
            CloseMultiSelect()
            if ($('#ddlEmployee').is(':visible')) {
                $('.select2-selection.select2-selection--multiple').css('display', 'block');
            }
        });
    }, [isandroidiospro, props.placeholder]);

    useEffect(() => {
        if (isandroidiospro === false) {
            AppplyMultiGruoupSelect();
        }
        else if (props.options && props.options.length > 0) {
            AppplyMultiGruoupSelect();
        }
        if ($('#resetBtnClickCustome').val() === "2") {
            setSelectedValue([]);
            $('#resetBtnClickCustome').val("");
        }

    }, [props.options, props.expandMinimizeShow]);

    useEffect(() => {
        if ($('#resetBtnClickCustome').val() === "1") {
            setSelectedValue([]);
            $('#resetBtnClickCustome').val("");
        }
    }, [resetOnClickValue])

    useEffect(() => {
        setTimeout(() => {
            if (isandroidiospro) {
                $(Control.ControlID + '.expandedWidth').select2("open");
            }
        }, 500);
        AddCommonMethod("OpenSelect2", () => $(Control.ControlID + '.expandedWidth').select2("open"))
        AddCommonMethod("CloseSelect2", () => $(Control.ControlID + '.expandedWidth').select2("close"))

    }, [])

    useEffect(() => {
        const handleKeyPress = (event) => {
            if (event.key === "Enter") {
                $(Control.ControlID + '.expandedWidth').select2("close");
                return;
            }
        };
        document.body.addEventListener('keypress', handleKeyPress);
        return () => {
            document.body.removeEventListener('keypress', handleKeyPress);
        };
    });

    const AppplyMultiGruoupSelect = () => {

        let appplyMultiGruoupSelect = $('select#' + props.ControlID);
        appplyMultiGruoupSelect.select2({
            // tags: true,
            // tokenSeparators: [',', ' '],
            templateResult: function (obj) {

                if (!obj.id) {
                    return obj.text;
                }
                let $obj = "";
                if (typeof (obj.element.dataset.groupid) !== undefined && obj.element.dataset.groupid !== null && obj.element.dataset.groupid !== "0") {
                    if (obj.element.dataset.isheader === "1") {
                        $obj = obj.element.dataset.defultselected === "true" ?
                            $(
                                '<span class="multicheck-checkbox defultselected"></span><label class="labelpadd" data-is-childs=true data-is-parent=true data-level=1  data-groupid="' + obj.element.dataset.groupid + '"><b>' + obj.text + ' </b></label>'
                            ) :
                            $(
                                '<span class="multicheck-checkbox"></span><label class="labelpadd" data-is-childs=true data-is-parent=true data-level=1  data-groupid="' + obj.element.dataset.groupid + '"><b>' + obj.text + ' </b></label>'
                            );
                    } else {
                        $obj = obj.element.dataset.defultselected === "true" ?
                            $(
                                '<span class="multicheck-checkbox multilocation-business-item defultselected"></span><label class="labelpadd multilocation-business-label" data-is-childs=true data-level=2 data-is-parent=false data-childs="' + $(obj.element).data("childids") + '" data-groupid="' + $(obj.element).data("groupid") + '">' + obj.text + '</label>'
                            ) :
                            $(
                                '<span class="multicheck-checkbox multilocation-business-item"></span><label class="labelpadd multilocation-business-label" data-is-childs=true data-level=2 data-is-parent=false data-childs="' + $(obj.element).data("childids") + '" data-groupid="' + $(obj.element).data("groupid") + '">' + obj.text + '</label>'
                            );
                    }
                } else {
                    $obj = $(
                        '<span class="multicheck-checkbox"></span><label class="labelpadd" data-groupid="0" data-level=0>' + obj.text + '</label>'
                    );
                }



                return $obj;
            },
            minimumInputLength: 0,
            selectAll: 2,
            isCountOnlyChild: 1,
            closeOnSelect: false,
            searchInputPlaceholder: props.searchInputPlaceholder,
            placeholder: props.placeholder,

            matcher: function (params, data) {
                if (typeof (params.term) == 'undefined' || params.term == null || params.term == "") {
                    return data;
                } else {
                    let searching = $.trim(params.term).toLowerCase();
                    let optionList = data.text.toLowerCase();

                    if (data.element.dataset.isParent === "false") {
                        if (optionList.indexOf(searching) > -1 || optionList.split(' ').reverse().join(" ").indexOf(searching) > -1) {
                            return data;
                        }
                    } else {
                        if (data.element.dataset.isParent === "true" && (optionList.indexOf(searching) > -1 || optionList.split(' ').reverse().join(" ").indexOf(searching) > -1)) {
                            let roleWiseGroup = $.grep(props.options, function (e) {
                                return (e.RoleID.toString() === data.element.dataset.groupid && e.IsParent.toString() !== "true")
                            })
                            let datamatched = roleWiseGroup.some((data) => (data.label.toLowerCase().indexOf(searching) > -1 || data.label.toLowerCase().split(' ').reverse().join(" ").indexOf(searching) > -1))

                            if (datamatched === true) {
                                return data
                            }
                        } else {
                            let roleWiseGroup = $.grep(props.options, function (e) {
                                return e.RoleID.toString() === data.element.dataset.groupid
                            })

                            let datamatched = roleWiseGroup.some((data) => (data.label.toLowerCase().indexOf(searching) > -1 || data.label.toLowerCase().split(' ').reverse().join(" ").indexOf(searching) > -1))

                            if (datamatched === true) {
                                return data
                            }
                        }
                    }
                }
                return null;
            }
        }).on('select2:open', function (e) {
            // Select the span element
            // var spanElement = document.querySelector('body > span > span > span.select2-search.select2-selectAll--dropdown');

            //     // Check if the element exists before attempting to remove it
            //     if (spanElement) {
            //         // Remove the span element
            //         spanElement.remove();
            //     }

            if (props.ControlID === "GroupddEmployee") {
                $('.modal').removeAttr('tabindex');
                $("body").addClass('noresultfound');
                $(".select2-container.GroupddEmployee").addClass('employee-multilocation-select');
                $(".select2.GroupddEmployee").removeClass('employee-multilocation-select');
                $('.select2-selection__choice__remove').removeAttr('title');
            }
            if ($('#CancelOnCLick').length === 0) {
                isandroidiospro === true && $('.select2-search').append('<button id="CancelOnCLick" class="mobileCancelSelect2">Cancel</button>');
            }
            $('.select2-search').on('click', '#CancelOnCLick', function () {
                CancelOnCLick();
            });
            $('.select2-search__field').on('focus', function () {
                var isFocused = $('.select2-search__field').is(':focus');
                if (isFocused) {
                    $('.select2-search--dropdown').addClass("selectSearchActive");
                }
            });
            $('.select2-search__field').on('blur', function () {
                const select2Input = $('.select2-search__field')[1]
                if (select2Input.value === undefined || select2Input.value === null || select2Input.value === "") {
                    $('.select2-search--dropdown').removeClass("selectSearchActive");
                }
            });
        }).on('select2:opening', function (e) {
        }).on('select2:select', function (e) {
            SelectDeSelectOptions(e, SelectionType.Select);
            //MultiValueSelectBox(e,SelectionType.Select);
        }).on('select2:unselect', function (e) {
            SelectDeSelectOptions(e, SelectionType.Deselect);
        }).on('select2:close', function (e) {
            $("body").removeClass('noresultfound');
        })
        setTimeout(() => {
            $(Control.ControlID + '.expandedWidth').select2("open");
            // if (isandroidiospro) {
            // }
        }, 500);
    }

    const SelectDeSelectOptions = (e, type) => {

        let optionSelected = []
        let parentID = $(e.params.data.element).data("groupid").toString();
        let childId = e.params.data.element.dataset.childids
        let parentIds = $("#" + props.ControlID).find("option[data-childids='" + childId + "']");
        let allParentElements = $("#" + props.ControlID).find("option[data-groupid=" + parentID + "]");
        let parentSelectedElements = $("#" + props.ControlID).find("option:selected[data-groupid=" + parentID + "][data-is-parent=false]");
        let parentLabel = $('label[data-is-parent=true][data-groupid=' + parentID + ']').first().parent();
        let childElements = $.grep(allParentElements, function (n) {
            return ($(n).data("is-parent") == false);
        });

        parentLabel.find("span.multicheck-checkbox").removeClass("multicheck-deselect-checkbox");
        allParentElements.first().data("is-parent-selected", false);
        optionSelected = $("#" + props.ControlID).val() == null ? [] : $("#" + props.ControlID).val();

        if (type === SelectionType.Select) {
            if ($(e.params.data.element).attr("class") === "parent_p") {
                $('label[data-groupid=' + parentID + ']').parent().attr("aria-selected", "true");

                allParentElements.first().data("is-parent-selected", "true");

                $(childElements).each(function (i, item) {
                    optionSelected.push($(item).val());
                    $($("#" + props.ControlID).find("option[data-childIds='" + $(item).val() + "']")).each(function (i, items) {
                        if (parentID !== items.getAttribute('data-groupid').toString()) {
                            $('label[data-is-parent=false][data-childs=' + $(item).val() + ']').parent().attr("aria-selected", "true");
                            optionSelected.push(items.getAttribute('data-groupid').toString());
                            $('label[data-is-parent=true][data-groupid=' + items.getAttribute('data-groupid').toString() + ']').first().parent().attr("aria-selected", "true");
                        }
                    })
                });
            } else {
                $('label[data-is-parent=false][data-childs="' + childId + '"]').parent().attr("aria-selected", "true");

                $(parentIds).each(function (i, item) {
                    optionSelected.push(item.getAttribute('data-groupid').toString());
                    $('label[data-is-parent=true][data-groupid=' + item.getAttribute('data-groupid').toString() + ']').first().parent().attr("aria-selected", "true");
                })
            }
        } else if (type === SelectionType.Deselect) {
            if ($(e.params.data.element).attr("class") === "parent_p") {
                $('label[data-groupid=' + parentID + ']').parent().attr("aria-selected", "false");

                $(allParentElements).each(function (i, item) {
                    $('label[data-is-parent=false][data-childs="' + $(item).val() + '"]').parent().attr("aria-selected", "false");
                    $($("#" + props.ControlID).find("option[data-childIds='" + $(item).val() + "']")).each(function (i, items) {
                        optionSelected = $.grep(optionSelected, function (n) {
                            $('label[data-is-parent=true][data-groupid=' + items.getAttribute('data-groupid').toString() + ']').first().parent().attr("aria-selected", "false");
                            return n !== items.getAttribute('data-groupid').toString();
                        });
                    })
                    optionSelected = $.grep(optionSelected, function (n) {
                        return n !== $(item).val();
                    });
                });

                if (optionSelected.length > 0) {
                    $(optionSelected).each(function (i, item) {
                        $($("#" + props.ControlID).find("option[data-childIds='" + item + "']")).each(function (i, items) {
                            optionSelected.push(items.getAttribute('data-groupid').toString());
                            $('label[data-is-parent=true][data-groupid=' + items.getAttribute('data-groupid').toString() + ']').first().parent().attr("aria-selected", "true");
                        })
                    })
                }
            } else {
                if (childElements != null && parentSelectedElements != null && (childElements.length !== parentSelectedElements.length)) {
                    let childParentID = $("#" + props.ControlID).find("option[data-childIds='" + childId + "']")

                    optionSelected = $.grep(optionSelected, function (n) {
                        return n !== childId;
                    });

                    $(childParentID).each(function (i, item) {
                        $('label[data-is-parent=true][data-groupid=' + item.getAttribute('data-groupid').toString() + ']').first().parent().attr("aria-selected", "false");
                        $('label[data-childs="' + childId + '"]').parent().attr("aria-selected", "false");
                        optionSelected = $.grep(optionSelected, function (n) {
                            return n !== item.getAttribute('data-groupid').toString();
                        });
                    })

                    if (optionSelected.length > 0) {
                        $(optionSelected).each(function (i, item) {
                            $($("#" + props.ControlID).find("option[data-childIds='" + item + "']")).each(function (i, items) {
                                optionSelected.push(items.getAttribute('data-groupid').toString());
                                $('label[data-is-parent=true][data-groupid=' + items.getAttribute('data-groupid').toString() + ']').first().parent().attr("aria-selected", "true");
                            })
                        })
                    }
                }
            }
        }
        if (newSelectedValues !== undefined) {
            const newlySelectedValues = optionSelected.filter(
                (value) => !defultSelection.includes(value)
            );
            newSelectedValues(newlySelectedValues.filter(item => isNaN(item) || !Number.isInteger(parseFloat(item))))
        }
        if (optionSelected.length > 0) {
            $("#" + props.ControlID).val(optionSelected.filter((value, index, self) => self.indexOf(value) === index)).trigger('change');
        }
        else {
            $("#" + props.ControlID).val('').trigger('change');
        }
        if (props.ControlID === "GroupddEmployee") {
            $('.select2-selection__choice__remove').removeAttr('title');
        }
    }
    const MultiValueSelectBox = (e, type) => {
        $(".expandedWidth").select2();
    }

    const CancelOnCLick = () => {
        $(".vg_clear_search").click();
        $('.select2-search--dropdown').removeClass("selectSearchActive");
    }

    return (
        <Fragment>
            <div>
                <fieldset>
                    <select id={props.ControlID} className="expandedWidth vgselect-dropdown prosale-select2" multiple="true" data-select-all="1"></select>
                </fieldset>
            </div>
            {isandroidiospro === true &&
                <Fragment>
                    <input id={"outsideclick" + props.ControlID} type="hidden" onClick={OutSideClick}></input>
                    <input id={"applyBtnClick" + props.ControlID} type="hidden" onClick={ApplyBtnClick}></input>
                    <input id="applyBtnClickValue" type="hidden"></input>
                    <input id="resetBtnClickCustome" type="hidden"></input>
                </Fragment>
            }
        </Fragment>
    )
}

export default MultiGroupSelectEmployee
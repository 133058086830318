import React, { useEffect, useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';
import ReactImageGallery from 'react-image-gallery';
import $ from 'jquery';
import { useChatContext } from '../../Vagaroconnect/Store/ChatProvider';
import { utils } from '../../utils/Utils';
import { MobileCallBackAction } from '../../Vagaroconnect/Binding/ChatBoatEnums';


const ImageGallery = (props) => {
    const { CommonMethod, AddCommonMethod } = useChatContext();
    const { isFromConnectApp, isAndroidIOSMobile } = props;
    const [imagename, setimagename] = useState(props.srcValues[0].name);    
    const galleryTouchRef = useRef(0);
        
    const handleDownload = async (event) => {
        event.preventDefault();
        const imageUrl = $(".image-gallery-center .image-gallery-image").attr("src");
        const response = await fetch(imageUrl);
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = encodeURI(url);
        link.download = 'image.png';
        document.body.appendChild(link);
        if (isFromConnectApp) {
            utils.CallBackGivenToMobile(MobileCallBackAction.DownlodImage, imageUrl);
        } else {
            link.click();
        }
        document.body.removeChild(link);
    };
    const closeGallery = () => {
        if (props.isCustomerChat) {
            var jsonData = JSON.stringify({ IframName: 'chatFrame', className: 'vc-iframeGalleryOpen' }).replace(/\\\"/g, '"').replace(/"/g, "\\\"");
            window.parent.postMessage('{"eventType": "iFrameRemoveClass","Data": "' + jsonData + '"}', '*');
            var jsonData = JSON.stringify({ IframName: 'body', className: 'vc-iframeGalleryOpen-parentBody' }).replace(/\\\"/g, '"').replace(/"/g, "\\\"");
            window.parent.postMessage('{"eventType": "iFrameRemoveClass","Data": "' + jsonData + '"}', '*');
        }
        if (props.expandMinimizeShow === 2) {
            var jsonData = JSON.stringify({ IframName: 'chatFrame', className: 'vc-iframeGalleryOpenMini' }).replace(/\\\"/g, '"').replace(/"/g, "\\\"");
            window.parent.postMessage('{"eventType": "iFrameRemoveClass","Data": "' + jsonData + '"}', '*');
        }
        props.setImageGalarry(false);
        AddCommonMethod("MobileCallBackForBack", () => { CommonMethod.Actionclick("messagesAreaheaderback") })
    }
    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape') {
                closeGallery(event);
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);
   
    const ImageNameShow = (index) => {

        for (let i = 0; i < props.srcValues.length; i++) {
            if (i === index) {
                setimagename(props.srcValues[i].name);
                return i;
            }
        }
        setimagename("");
        return -1;
    }

    return (
        <div className='image-popup-wrap'
            onTouchStart={(event) => {
                const { clientY } = event.touches[0];
                galleryTouchRef.current = clientY
            }}
            onTouchEnd={(event) => {
                const { clientY } = event.changedTouches[0];
                if (isAndroidIOSMobile && (galleryTouchRef.current + 160 < clientY)) {
                    galleryTouchRef.current = 0;
                    closeGallery();
                }
            }}
        >
            <Modal className="image-popup-wrap" show={true} fullscreen={true}>
                <Modal.Header>
                    <Modal.Title>{imagename}</Modal.Title>
                    <div className='imgModalBtnWrap'>
                        <a className='download-close' href={false} onClick={(e) => { handleDownload(e) }}>
                            <div className='download'>
                                <i class="fa-duotone fa-download"></i>
                            </div>
                        </a>
                        <a id='btnCloseImageGallary' className="btn-close" onClick={(e) => { closeGallery(e) }}></a>
                    </div>
                </Modal.Header>
                <Modal.Body><ReactImageGallery items={props.srcValues}  onSlide={(e) => ImageNameShow(e)} /></Modal.Body>
            </Modal>
        </div>
    )
}

export default ImageGallery
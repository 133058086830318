import moment from 'moment';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { AsyncAjaxGet, AsyncAjaxPost, AsyncAjaxPut } from '../component/Common/AsyncAjaxServiceV2';
import { utils, DateFormate, ConvertUtcToLocal, DateFormateFomChat, isAndroidIOSTabIpad, HandleHistory, StoreLocalConversation } from '../utils/Utils';
import { useChatContext } from './Store/ChatProvider';
import { useDispatch } from 'react-redux';
import useGenerateToken from '../component/Hooks/useGenerateToken';
import { LeftControlNames, MobileCallBackAction, PopupTypes, SelectionControlTypeEnum, VagaroConnectMessageType, middleScrollHeight } from './Binding/ChatBoatEnums';
import { createPortal } from 'react-dom';
import SuccessAlert from '../component/Common/SuccessAlert';
import ImageGallery from '../component/Common/ImageGallery';
import { StenCiledConvertions } from './Binding/StenciedData';
import { ConnectionStatus } from './Binding/ChatBoatEnums';
import ReactDOM from 'react-dom';
import { Groupicon, Usericon } from './CommonComponent/Icons';
import MultiGroupSelectEmployee from '../component/Controls/MultiGroupSelectEmployee';
import MerchantsSingleSelecttwo from '../component/Controls/MerchantsSingleSelecttwo';
import Dropdown from 'react-bootstrap/Dropdown';
import $ from "jquery";



const ChatArea = (props) => {
    const { chatSelectionID, chatSelection, ChatboadSelection, connection, connectionStatus, setConnectionStatus, CommonMethod, AddCommonMethod } = useChatContext();
    const {
        merchantId,
        EditInput,
        ReactBusinessName,
        ReactFirstName,
        ReactLastName,
        ReactProfileImage,
        ReactCdnUrl,
        messageConvertions,
        setMessageConvertions,
        pageIndex,
        setPageIndex,
        tempChatData,
        setTempChatData,
        LoginUserIdHash,
        BtnRightsidepenal,
        rightsidePenal,
        isNewChat,
        LeftOption,
        ViewEmployee,
        ViewCustomer,
        ExpandMinimizeButtonClick,
        expandMinimizeShow,
        convertionData,
        isCustomerChat,
        UserFromConversation,
        setUserFromConversation,
        chatAreaShow,
        setChatAreaShow,
        ReactBusinessImage,
        ModifyCustomer,
        ModifyEmployee,
        setConvertionData,
        setShowFirstLoader,
        middleArea,
        ReactUserID,
        searchText,
        setsearchText,
        ManageCustomerViewRights,
        LoginUserIDV2,
        ChatBoxCloseClick,
        isFromConnectApp,
        setIsViewChanging,
        isViewChanging,
        UpsertChannel,
        customerData,
        isAndroidIOSMobile,
        isIOSMobile,
        isMultilocationBusiness,
        CheckLoginUserIsRemovedFromChat,
        device,
        pushNotificationMessageId,
        setConversetionChatSelection,
        employeeGroupDetails, ManageCustomerModifyRights,
        setnewChatSelectedEmployee,
        countryID
    } = props;
    const [restrictPaging, setRestrictPaging] = useState(true);
    const [holdFuctiontimer, setHoldFuctiontimer] = useState(null);
    const [imageGallery, setImageGallery] = useState(false);
    const [galleryImages, setGalleryImages] = useState(false);
    const [showEditDeleteAction, setshowEditDeleteAction] = useState(null);
    const [showEditEditor, setshowEditEditor] = useState(null);
    let isIpad = /Macintosh/i.test(navigator.userAgent) && navigator.maxTouchPoints && navigator.maxTouchPoints > 1;
    let isIpadold = /iPad/i.test(navigator.userAgent);
    let isTablet = /VagaroAndroidTablet/i.test(navigator.userAgent);

    //const middleArea = useRef(null);
    const dispatch = useDispatch();
    const [renderState, renderPage] = useState(false);
    const isLoadedData = useRef(false);
    const StoreScrollHeight = useRef(0);
    const heightScroll = useRef(0);
    const ScrollisBottom = useRef(false);
    const VagaroAPIEnum = {
        UploadImage: `connect/${merchantId}/sendfile`,
    }


    const doRender = () => {
        renderPage(!renderState);
    }

    const removeDuplicateMessages = (data) => {
        const uniqueMessages = {};
        data.forEach(conversation => {

            if (!uniqueMessages[conversation.messageId]) {
                uniqueMessages[conversation.messageId] = conversation;
            }
        });
        const filteredConversations = Object.values(uniqueMessages);
        return filteredConversations;
    };

    const mergeChatData = (chatData, searchPageIndex) => {
        var mergedObject = chatData;
        if (tempChatData !== undefined && tempChatData !== null && tempChatData.length > 0 && !UserFromConversation) {
            if (searchPageIndex !== undefined) {
                mergedObject = tempChatData.concat(mergedObject);
            } else {
                mergedObject = mergedObject.concat(tempChatData);
            }
        }
        setUserFromConversation(false);

        // debugger
        //if user blocked and msg sent
        // mergedObject = mergedObject.filter((d) => d.messageId !== props.sendResponse?.messageId)

        mergedObject = removeDuplicateMessages(mergedObject);

        const groupedMessages = mergedObject.reduce((acc, message) => {
            const date = moment(message.messageDate).format('YYYY-MM-DD');
            if (!acc[date]) {
                acc[date] = [];
            }
            acc[date].push(message);
            return acc;
        }, {});
        setTempChatData(mergedObject);
        setMessageConvertions(groupedMessages);
    }

    const GetCustomerMessageConvertion = async (chatSelectionParam, searchPageIndex, isScroll = false) => {
        isLoadedData.current = false;
        const accesstoken = await useGenerateToken(dispatch);
        if ((("Customer" === LeftOption && ViewCustomer === false) || ("Employee" === LeftOption && ViewEmployee === false)) && !isCustomerChat) {
            // return ReactDOM.render(
            //     <Fragment>
            //         <SuccessAlert
            //             alertType={"warning"}
            //             strMessage={"You Don't Have Rights"}
            //             displaytime={2000}
            //         />
            //     </Fragment>,
            //     document.getElementById("OpenDialog1")
            // );
            utils.ShowAlert("Restricted Access", "You have not been given access to this page. Contact your account admin or business owner to get access.", "Close");
        }
        else {
            let chatSection = chatSelectionParam.ConversationId === undefined || chatSelectionParam.ConversationId === null || chatSelectionParam.ConversationId === "" ? chatSelectionParam : chatSelectionParam.ConversationId
            let userId = "";
            let apiPageIndex = 1;
            if (chatSelectionParam.Type === "Customer") {
                userId = chatSelectionParam.Id;
            }
            if (isCustomerChat) {
                userId = LoginUserIDV2;
            }
            const MessageId = (chatSelectionParam.AllData !== undefined && chatSelectionParam.AllData !== null && chatSection.AllData !== "") ? chatSelectionParam.AllData.messageId === undefined || chatSelectionParam.AllData.messageId === null || chatSelectionParam.AllData.messageId === "" ? "" : chatSelectionParam.AllData.messageId : ""
            if (isCustomerChat) {
                window.parent.postMessage('{"eventType": "vagaroConnectShowHideLoader","Data": ' + false + '}', '*');
            }
            if (searchPageIndex !== undefined) {
                apiPageIndex = searchPageIndex;
            } else {
                apiPageIndex = pageIndex;
            }
            await AsyncAjaxGet(
                "connect/" + merchantId + "/conversations/detail?ConversationId=" + chatSection + "&PageIndex=" + apiPageIndex + "&PageSize=20&MessageId=" + MessageId + "&SearchKey=" + searchText.trim() + "&LoggedInUserId=" + LoginUserIDV2 + "&UserId=" + userId,
                accesstoken,
                function onSuccess(data) {
                    try {
                        if (data != null && data.data != null && data.data.data != null) {
                            if (isCustomerChat) {
                                if (data.data.data.conversationId !== undefined && data.data.data.conversationId !== null) {
                                    chatSelection.IsBlocked = data.data.data.isBlocked;
                                    chatSelection.AllData.isBlocked = data.data.data.isBlocked;
                                }
                            }
                            if (!isCustomerChat) {
                                if ((searchText.length > 0 || MessageId !== "") && data.data.data.pageIndex > 1) {
                                    chatSelection.AllData.pageIndex = data.data.data.pageIndex; // Temp
                                    setRestrictPaging(false);
                                    // setisRestrictPageIndex(true);
                                    isLoadedData.current = false;
                                    setPageIndex(chatSelection.AllData.pageIndex);
                                }
                            }
                            if (data.data.data.conversations.length > 0) {
                                if (data.data.data.conversations.length < 10) {
                                    setRestrictPaging(false);
                                } else {
                                    setRestrictPaging(true);
                                    if (searchPageIndex === undefined && isScroll === false) {
                                        middleArea.current.scrollTop = 30;
                                    }
                                }
                                var messageData = data.data.data.conversations;
                                if (isCustomerChat && data.data.data.isBlocked === true) {
                                    var blockMessage = {
                                        message: `${ReactBusinessName} is currently not accepting messages at this time. Please try calling the business directly.`,
                                        isDeleted: false,
                                        isGroupUpdateMessage: true,
                                        messageDate: new Date()
                                    }
                                    messageData.push(blockMessage)
                                }
                                // set isCustomerOnline value before sending message to customer 
                                chatSelection.AllData.isCustomerOnline = data.data.data.isCustomerOnline;
                                if (data.data.data.isBlocked === true) {
                                    chatSelection.IsBlocked = data.data.data.isBlocked;
                                    chatSelection.AllData.isBlocked = data.data.data.isBlocked;
                                }
                                mergeChatData(messageData, searchPageIndex);
                                setIsViewChanging(false);
                                setShowFirstLoader(false);
                                isLoadedData.current = true;
                            } else {
                                setRestrictPaging(false);
                                isLoadedData.current = false;
                                if (isCustomerChat) {
                                    window.parent.postMessage('{"eventType": "vagaroConnectShowHideLoader","Data": ' + false + '}', '*');
                                }
                            }
                            if (isFromConnectApp && isAndroidIOSTabIpad() && (window.PrevOrientation && window.PrevOrientation === "portrait" && window.PrevChnageContainerClose === true)) {
                                CommonMethod.RemoveElementbyClass("select2-container");
                            }
                            window.PrevChnageContainerClose = true;
                        }
                        else {
                            setRestrictPaging(false);
                            isLoadedData.current = false;
                            if (isCustomerChat) {
                                window.parent.postMessage('{"eventType": "vagaroConnectShowHideLoader","Data": ' + false + '}', '*');
                            }
                            utils.appInsightTrack(
                                props.ReactBusinessId,
                                props.ReactUserID,
                                "Conversation Detail",
                                "",
                                data != null && data.data != null ? data.data : ""
                            );
                        }
                    } catch (error) {
                        setRestrictPaging(false);
                        isLoadedData.current = false;
                        if (isCustomerChat) {
                            window.parent.postMessage('{"eventType": "vagaroConnectShowHideLoader","Data": ' + false + '}', '*');
                        }
                        utils.appInsightTrack(
                            props.ReactBusinessId,
                            props.ReactUserID,
                            "Conversation Detail",
                            "",
                            error
                        );
                    }
                },
                function OnError(data) {
                    setRestrictPaging(false);
                    isLoadedData.current = false;
                    if (isCustomerChat) {
                        window.parent.postMessage('{"eventType": "vagaroConnectShowHideLoader","Data": ' + false + '}', '*');
                    }
                    utils.appInsightTrack(
                        props.ReactBusinessId,
                        props.ReactUserID,
                        "Conversation Detail",
                        "",
                        data
                    );
                },
                true
            );
        }
    }

    useEffect(() => {
        const handleInputFocus = () => {
            let CheckEdit = document.getElementsByClassName('ckeditor_2')
            if (middleArea.current !== null && isFromConnectApp && CheckEdit.length === 0) {
                let windowHeight = $(window).height();
                let VisualHeight = window.visualViewport.height;
                setTimeout(() => {
                    if (windowHeight !== VisualHeight) {
                        if (middleArea.current.scrollTop === heightScroll.current) {
                            if (StoreScrollHeight.current === 0) {
                                middleArea.current.scrollTop = heightScroll.current + VisualHeight + windowHeight;
                            } else {
                                middleArea.current.scrollTop = StoreScrollHeight.current + VisualHeight + middleScrollHeight;
                            }
                        }
                        else {
                            if (ScrollisBottom.current) {
                                middleArea.current.scrollTop = StoreScrollHeight.current + VisualHeight + middleScrollHeight;
                            } else {
                                //middleArea.current.scrollTop = StoreScrollHeight.current;
                            }
                        }
                    }
                }, 250);
            }
        };
        window.visualViewport.addEventListener('resize', () => {
            handleInputFocus()
        });
        document.body.addEventListener('focusin', handleInputFocus);
        return () => {
            window.visualViewport.removeEventListener('resize', handleInputFocus);
            document.body.removeEventListener('focusin', handleInputFocus);
        };
    }, []);

    const UploadImageRequest = (conversationId, src, fileName) => {
        const RequestData = {};
        RequestData.ConversationId = conversationId;
        RequestData.FileContent = src;
        RequestData.UploadFileName = fileName;        
        return RequestData;
    }

    const ImageUploadOnDrive = async (src, fileName, onComplete, onfailer) => {
        const access_token = await useGenerateToken(dispatch);
        await AsyncAjaxPost(
            VagaroAPIEnum.UploadImage,
            UploadImageRequest(chatSelection.ConversationId, src, fileName),
            access_token,
            function onSuccess(data) {
                try {
                    if (data != null && data.data != null && data.data.data != null) {
                        if (onComplete) {
                            onComplete(data);
                        }
                        // return data.data.data;
                    }
                    else {
                        onfailer(data);
                        utils.appInsightTrack(
                            props.ReactBusinessId,
                            props.ReactUserID,
                            "Upload Image",
                            UploadImageRequest(chatSelection.ConversationId, src, fileName),
                            data != null && data.data != null ? data.data : ""
                        );
                    }
                } catch (error) {
                    onfailer(data);
                    utils.appInsightTrack(
                        props.ReactBusinessId,
                        props.ReactUserID,
                        "Upload Image",
                        UploadImageRequest(chatSelection.ConversationId, src, fileName),
                        error
                    );
                }
            },
            function OnError(data) {
                onfailer(data);
                utils.appInsightTrack(
                    props.ReactBusinessId,
                    props.ReactUserID,
                    "Upload Image",
                    UploadImageRequest(chatSelection.ConversationId, src, fileName),
                    data
                );
            },
            true
        );
    }

    const RemoveImageBase64 = async (SendData, onSuccess) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(SendData, 'text/html');

        const images = doc.querySelectorAll('img');
        if (images.length > 0) {
            let count = 0;
            images.forEach(async (img) => {
                const alt = img.getAttribute('alt');
                const src = img.getAttribute('src');
                if ((alt !== 'Gif' && alt !== 'VagaroDrive') && (src && src.startsWith('data:image'))) {
                    const src = img.getAttribute('src');
                    await ImageUploadOnDrive(src, alt, data => {
                        img.setAttribute('src', data.data.data);
                        img.setAttribute('alt', alt);
                        count++;
                        if (count === images.length) {
                            const updatedHtmlString = doc.body.innerHTML;
                            onSuccess(updatedHtmlString);
                        }
                    }, error => { return "" });
                }
                else {
                    count++;
                    if (count === images.length) {
                        const updatedHtmlString = doc.body.innerHTML;
                        onSuccess(updatedHtmlString);
                    }
                }
            })
        }
        else {
            const updatedHtmlString = doc.body.innerHTML;
            onSuccess(updatedHtmlString);
        }
    }

    const EditMessagesRequest = async (RequestObject, ResponseRequestData) => {
        await RemoveImageBase64(RequestObject.message, ResponseMessage => {
            if (ResponseMessage !== false) {
                const parser = new DOMParser();
                const doc = parser.parseFromString(ResponseMessage, 'text/html');
                const images = doc.querySelectorAll('img');
                const RequestData = {};
                RequestData.attachments = [];
                if (images.length > 0) {
                    images.forEach(img => {
                        const FileUrl = img.getAttribute('src');
                        const FileName = img.getAttribute('alt');
                        RequestData.attachments.push({ FileUrl, FileName });
                    });
                }
                var modifiedHtmlString = ResponseMessage.replace(/(<img\b[^>]*>)\s*(<br>|&nbsp;)*\s*(?=<img\b)/gi, '$1');
                RequestData.messageId = RequestObject.messageId;
                RequestData.conversationId = chatSelection.ConversationId;
                RequestData.senderId = RequestObject.sentBy;
                RequestData.message = modifiedHtmlString;
                RequestData.isnote = RequestObject.isNote;
                RequestData.action = "Edit";
                RequestData.SentByHash = isCustomerChat ? LoginUserIdHash : chatSelection.AllData.customerIdHash;
                RequestData.messageDate = RequestObject.messageDate;
                RequestData.chanelId = LeftOption === LeftControlNames.Customer ? chatSelection.AllData.chanelId : props.MerchantChannelId.toString();
                ResponseRequestData(RequestData);
            }
        });
    }

    const EditMessages = async (messageData) => {
        const access_token = await useGenerateToken(dispatch);
        if ((("Customer" === LeftOption && ModifyCustomer === false) || ("Employee" === LeftOption && ModifyEmployee === false)) && !isCustomerChat) {
            // return ReactDOM.render(
            //     <Fragment>
            //         <SuccessAlert
            //             alertType={"warning"}
            //             strMessage={"You Don't Have Rights"}
            //             displaytime={2000}
            //         />
            //     </Fragment>,
            //     document.getElementById("OpenDialog1")
            // );
            utils.ShowAlert("Restricted Access", "You have not been given access to this page. Contact your account admin or business owner to get access.", "Close");
        }
        else {
            setshowEditDeleteAction(null);
            setshowEditEditor(null);
            await EditMessagesRequest(messageData, async Response => {
                await AsyncAjaxPut(
                    "connect/" + merchantId + "/editmessage",
                    access_token,
                    Response,
                    function onSuccess(data) {
                        try {
                            if (data != null && data.data != null && data.data.data != null) {

                                var sendMsgRequestData = {};
                                var msgResponse = data.data.data;
                                sendMsgRequestData.messageId = msgResponse.id;
                                sendMsgRequestData.conversationId = msgResponse.conversationId;
                                sendMsgRequestData.SenderId = msgResponse.senderId;
                                sendMsgRequestData.fullname = msgResponse.fullName;
                                sendMsgRequestData.message = msgResponse.message;
                                sendMsgRequestData.MemberIds = msgResponse.MemberIds;
                                sendMsgRequestData.status = msgResponse.status;
                                sendMsgRequestData.isnote = msgResponse.isNote;
                                sendMsgRequestData.attachment = msgResponse.attachment;
                                sendMsgRequestData.isMessageFromCustomer = msgResponse.isMessageFromCustomer
                                sendMsgRequestData.isDeleted = false;
                                sendMsgRequestData.isGroupUpdateMessage = false;
                                sendMsgRequestData.SentByHash = msgResponse.senderIdHash;
                                sendMsgRequestData.IsRemove = false;
                                window.setEditMessage(sendMsgRequestData);
                                // setshowEditDeleteAction(null);
                                // setshowEditEditor(null);
                                // if (connection.state === 'Connected') {
                                //     if (LeftOption === LeftControlNames.Customer) {
                                //         //business to customer / customer to business
                                //         connection.invoke('SendGroup', Response.SentByHash + window.ReactBusinessId, Response);
                                //     } else {
                                //         //employee to employee 
                                //         connection.invoke('SendGroup', window.ReactBusinessId + "", Response);
                                //     }
                                // }
                            }
                            else {
                                utils.appInsightTrack(
                                    props.ReactBusinessId,
                                    props.ReactUserID,
                                    "Edit Message",
                                    Response,
                                    data != null && data.data != null ? data.data : ""
                                );
                            }
                        } catch (error) {
                            utils.appInsightTrack(
                                props.ReactBusinessId,
                                props.ReactUserID,
                                "Edit Message",
                                Response,
                                error
                            );

                        }
                    },
                    function OnError(data) {
                        utils.appInsightTrack(
                            props.ReactBusinessId,
                            props.ReactUserID,
                            "Edit Message",
                            Response,
                            data
                        );

                    },
                    true
                );

            });
        }
    }

    const DeleteUndoMessageRequest = (requestdata) => {
        const RequestData = {};
        RequestData.MessageId = requestdata.messageId;
        RequestData.ConversationId = chatSelection.ConversationId;
        RequestData.chanelId = LeftOption === LeftControlNames.Customer ? chatSelection.AllData.chanelId : props.MerchantChannelId.toString();
        return RequestData;
    }

    const DeleteUndoMessage = async (messageData) => {
        const access_token = await useGenerateToken(dispatch);
        if ((("Customer" === LeftOption && ModifyCustomer === false) || ("Employee" === LeftOption && ModifyEmployee === false)) && !isCustomerChat) {
            // return ReactDOM.render(
            //     <Fragment>
            //         <SuccessAlert
            //             alertType={"warning"}
            //             strMessage={"You Don't Have Rights"}
            //             displaytime={2000}
            //         />
            //     </Fragment>,
            //     document.getElementById("OpenDialog1")
            // );
            utils.ShowAlert("Restricted Access", "You have not been given access to this page. Contact your account admin or business owner to get access.", "Close");
        }
        else {
            await AsyncAjaxPut(
                `connect/${merchantId}/message/${messageData.isDeleted ? "Delete" : "UndoDelete"}`,
                access_token,
                DeleteUndoMessageRequest(messageData),
                function onSuccess(data) {
                    try {
                        if (data != null && data.data != null && data.data.data != null) {
                            const sentRequestData = {};
                            sentRequestData.messageId = messageData.messageId;
                            sentRequestData.conversationId = chatSelection.ConversationId;
                            sentRequestData.senderId = messageData.sentBy;
                            sentRequestData.message = messageData.message;
                            sentRequestData.isnote = messageData.isNote;
                            sentRequestData.action = messageData.isDeleted ? "Delete" : "UndoDelete";
                            sentRequestData.isDeleted = messageData.isDeleted ? true : false;
                            sentRequestData.SentByHash = isCustomerChat ? LoginUserIdHash : chatSelection.AllData.customerIdHash;
                            sentRequestData.messageDate = messageData.messageDate;
                            sentRequestData.isMessageFromCustomer = messageData.isMessageFromCustomer
                            if (isFromConnectApp) {
                                handleOutsideClick();
                            }
                            // if (connection.state === 'Connected') {
                            //     if (LeftOption === LeftControlNames.Customer) {
                            //         //business to customer / customer to business
                            //         connection.invoke('SendGroup', sentRequestData.SentByHash + window.ReactBusinessId, sentRequestData);
                            //     } else {
                            //         //employee to employee 
                            //         connection.invoke('SendGroup', window.ReactBusinessId + "", sentRequestData);
                            //     }
                            // }
                            doRender();
                        }
                        else {
                            utils.appInsightTrack(
                                props.ReactBusinessId,
                                props.ReactUserID,
                                "Delete Undo-Delete Message",
                                DeleteUndoMessageRequest(messageData),
                                data != null && data.data != null ? data.data : ""
                            );
                        }
                    } catch (error) {
                        utils.appInsightTrack(
                            props.ReactBusinessId,
                            props.ReactUserID,
                            "Delete Undo-Delete Message",
                            DeleteUndoMessageRequest(messageData),
                            error
                        );

                    }
                },
                function OnError(data) {
                    utils.appInsightTrack(
                        props.ReactBusinessId,
                        props.ReactUserID,
                        "Delete Undo-Delete Message",
                        DeleteUndoMessageRequest(messageData),
                        data
                    );

                },
                true
            );
        }
    }


    window.callBackChatConversation = (response) => {
        response.senderFirstName = ReactFirstName;
        response.senderLastName = ReactLastName;
        setMessageConvertions(prevState => {
            const updatedChatData = { ...prevState };
            const dateKey = moment(response.messageDate).format('YYYY-MM-DD');
            if (updatedChatData[dateKey]) {
                updatedChatData[dateKey].push(response);
            } else {
                updatedChatData[dateKey] = [response];
            }
            return updatedChatData;
        });
        if (middleArea.current !== null) {
            middleArea.current.scrollTop = middleArea.current?.scrollHeight;
            StoreScrollHeight.current = middleArea.current.scrollTop;
            ScrollisBottom.current = true;
        }
    }

    window.setMessageIdIntoMessageObject = (tempMsgData, sendMessageobject) => {
        if (tempMsgData.conversationId === chatSelection.ConversationId) {
            const dateKey = moment(tempMsgData.messageDate).format('YYYY-MM-DD');
            setMessageConvertions(prevState => {
                if (prevState[dateKey]) {
                    return {
                        ...prevState,
                        [dateKey]: prevState[dateKey].map((x) => {
                            if (x.tempMessageId === tempMsgData.tempMessageId && sendMessageobject.isGroupUpdateMessage === false) {
                                if (sendMessageobject?.attachment?.length > 0) {
                                    return {
                                        ...x,
                                        message: sendMessageobject.message,
                                        messageId: sendMessageobject.messageId,
                                        senderFirstName: sendMessageobject.senderFirstName,
                                        senderLastName: sendMessageobject.senderLastName
                                    };
                                }
                                else {
                                    return {
                                        ...x,
                                        messageId: sendMessageobject.messageId,
                                        senderFirstName: sendMessageobject.senderFirstName,
                                        senderLastName: sendMessageobject.senderLastName
                                    };
                                }
                            }
                            else {
                                if (x.sentByHash === LoginUserIdHash && sendMessageobject.isGroupUpdateMessage === false) {
                                    return {
                                        ...x,
                                        senderFirstName: sendMessageobject.senderFirstName,
                                        senderLastName: sendMessageobject.senderLastName
                                    };
                                }
                            }
                            return x;
                        })
                    };
                }
                return prevState;
            });
        }
        if (!isCustomerChat) {
            const updatedData = convertionData.map(d =>
                d.conversationId === tempMsgData.conversationId
                    ? { ...d, messageId: sendMessageobject.messageId, isDeleted: false }
                    : d
            );
            setConvertionData(updatedData)
        }
    }

    window.setEditMessage = (tempMsgData) => {
        if (tempMsgData.conversationId === chatSelection.ConversationId) {
            const dateKey = moment(tempMsgData.messageDate).format('YYYY-MM-DD');
            setMessageConvertions(prevState => {
                if (prevState[dateKey]) {
                    return {
                        ...prevState,
                        [dateKey]: prevState[dateKey].map((x) => {
                            if (x.messageId === tempMsgData.messageId) {
                                return {
                                    ...x,
                                    messageId: tempMsgData.messageId,
                                    message: tempMsgData.message
                                };
                            }
                            return x;
                        })
                    };
                }
                return prevState;
            });
        }
        if (!isCustomerChat) {
            const updatedData = convertionData.map(d =>
                d.conversationId === tempMsgData.conversationId
                    ? { ...d, messageId: tempMsgData.messageId, isDeleted: false }
                    : d
            );
            setConvertionData(updatedData)
        }
    }

    useEffect(() => {
        window.GetConversationById();
    }, [chatSelectionID]);



    window.GetConversationById = () => {
        if (chatSelection.ConversationId !== "") {
            if (!isCustomerChat && (chatSelection.AllData.chanelId === "" || chatSelection.AllData.chanelId === undefined)) {
                if (LeftOption === LeftControlNames.Customer) {
                    if (connection.state === 'Connected') {
                        var sendGroupData = {
                            "messageType": VagaroConnectMessageType.ChannelSubscriptionMessage,
                            "message": 'BUSINESS_JOINED'
                        }
                        connection.invoke('JoinGroup', chatSelection.AllData.customerIdHash + props.ReactBusinessId.toString() + "", sendGroupData.message);
                        connection.invoke('SendGroup', props.ReactBusinessId.toString() + "", sendGroupData);
                    }
                } else {
                    chatSelection.AllData.chanelId = props.MerchantChannelId.toString();
                }
            }
            if (!isCustomerChat) {
                //console.log("Upsert channel for Chat selection")
                UpsertChannel(chatSelection.AllData.customerId, chatSelection?.AllData?.customerIdHash, isFromConnectApp ? true : false);
            }
            setshowEditEditor(null);
            setshowEditDeleteAction(null);
            GetCustomerMessageConvertion(chatSelection);
            // if (connection.state === 'Connected') {
            //     if (!isCustomerChat) {
            //         window.JoinToGroupBusiness(connection, chatSelection.AllData.customerIdHash);
            //     }
            // }

        }
        if (middleArea.current !== null) {
            middleArea.current.scrollTop = middleArea.current?.scrollHeight;
        }
        // utils.SetGroupNameWidth();
        AddCommonMethod("MessageConvertionRender", () => { GetCustomerMessageConvertion(chatSelectionID); })
        AddCommonMethod("ConvertionDetailRender", () => { GetCustomerMessageConvertion(chatSelection, undefined, true); })
    }

    // useEffect(() => {
    //     console.log(connectionStatus);
    //     if (connectionStatus != null && connectionStatus === 'Connected' && convertionData !== undefined && convertionData !== null && convertionData.length > 0) {
    //         //            window.JoinToAllConversations(connection)
    //         //console.log("business has started to connect all conversations");

    //         convertionData.forEach(async (data) => {
    //             window.JoinToGroupBusiness(connection, data.customerIdHash);
    //         })

    //         //console.log("business has connected to all conversations");
    //     }
    // }, [connectionStatus, convertionData]);

    // function connectWindowSizeMessageHeader() {
    //     utils.SetGroupNameWidth();
    // }
    // window.onresize = connectWindowSizeMessageHeader;

    window.JoinToGroupBusinessReconnect = (connection) => {

        //console.log("business has started to reconnect all conversations");
        if (convertionData !== undefined && convertionData !== null && convertionData.length > 0) {
            convertionData.forEach(async (data) => {
                window.JoinToGroupBusiness(connection, data.customerIdHash);
            })
        }
        //console.log("business has reconnected");

    };
    window.JoinToAllConversations = (connection) => {

        //console.log("business has started to connect all conversations");
        if (convertionData !== undefined && convertionData !== null && convertionData.length > 0) {
            convertionData.forEach(async (data) => {
                window.JoinToGroupBusiness(connection, data.customerIdHash);
            })
        }
        //console.log("business has connected to all conversations");

    };
    //useEffect(() => {
    //        console.log("business");
    //        if (connection) {               

    //            connection.onreconnected(() => {
    //                setConnectionStatus(ConnectionStatus.Connected);
    //                console.log('Reconnected Business');
    //                window.JoinToGroupBusiness(connection, chatSelection.AllData.customerIdHash);
    //            });


    //        }


    //}, [connection]);

    useEffect(() => {
        if (((pageIndex !== chatSelection.AllData.pageIndex) || (pageIndex > 1 && restrictPaging === true)) && !props.newChat) {
            if (chatSelection.ConversationId !== undefined && chatSelection.ConversationId !== null && chatSelection.ConversationId !== "") {
                GetCustomerMessageConvertion(chatSelection.ConversationId);
            }
        }

    }, [pageIndex]);

    useEffect(() => {
        if (middleArea.current !== null && middleArea.current.scrollTop === 0 && pageIndex === 1 && !props.newChat && searchText.length < 3) {
            setTimeout(() => {
                middleArea.current.scrollTop = middleArea.current?.scrollHeight;
                heightScroll.current = middleArea.current.scrollTop;
            }, 150);
        }
        if (messageConvertions !== undefined && messageConvertions !== null && messageConvertions !== "") {
            if (chatSelection.AllData !== undefined && chatSelection.AllData !== null && chatSelection.AllData !== "") {
                var messageId = "";
                if (searchText.length > 0 && props.conClickScroll.current) {
                    messageId = chatSelection.AllData.messageId;
                } else if (isAndroidIOSMobile && pushNotificationMessageId && pushNotificationMessageId?.current !== "" && chatSelection.AllData.pageIndex !== undefined) {
                    messageId = pushNotificationMessageId.current;
                }

                if (messageId !== undefined && messageId !== "") {
                    let messageElement = document.getElementById(messageId);
                    if (messageElement) {
                        setTimeout(() => {
                            messageElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            props.conClickScroll.current = false;
                            pushNotificationMessageId.current = "";
                        }, 200);
                    }
                }
                else {
                    if (searchText.length < 3 && pageIndex === 1) {
                        const imageElements = document.querySelectorAll('.messages img');
                        let loadedImages = 0;

                        const handleImageLoad = () => {
                            loadedImages++;
                            if (loadedImages < 8) {
                                if (middleArea.current !== undefined && middleArea.current !== null) {
                                    middleArea.current.scrollTop = middleArea.current?.scrollHeight;
                                    heightScroll.current = middleArea.current.scrollTop;
                                }
                            }
                        };

                        if (imageElements.length === 0) {
                            if (middleArea.current !== undefined && middleArea.current !== null) {
                                middleArea.current.scrollTop = middleArea.current?.scrollHeight;
                                heightScroll.current = middleArea.current.scrollTop;
                            }
                        }

                        imageElements.forEach(image => {
                            image.addEventListener('load', handleImageLoad);
                        });

                        return () => {
                            imageElements.forEach(image => {
                                image.removeEventListener('load', handleImageLoad);
                            });
                        };
                    }
                }
            }
        }

    }, [messageConvertions])

    const onClickBackButtonFromChatArea = () => {
        if (isFromConnectApp && employeeGroupDetails?.current && employeeGroupDetails.current !== "") {
            setConversetionChatSelection(employeeGroupDetails.current, true);
            CommonMethod.Actionclick("messagesAreaGroupDetail")
            employeeGroupDetails.current = "";
        } else {
            setnewChatSelectedEmployee("");
            setChatAreaShow(false);
            ChatboadSelection(SelectionControlTypeEnum.None, "", "", "", "", false, false, {});
        }
        if (isFromConnectApp === false && expandMinimizeShow === 2) {
            StoreLocalConversation(0);
        }
        window.DeleteChannelDetail(false);
        var jsonData = JSON.stringify({ IframName: 'chatFrame', className: 'ifream-chatActive' }).replace(/\\\"/g, '"').replace(/"/g, "\\\"");
        window.parent.postMessage('{"eventType": "iFrameRemoveClass","Data": "' + jsonData + '"}', '*');
    }

    const ShowDesignMessage = (message, messageObject) => {
        const imageRegex = /<img[^>]+src="([^">]+)"[^>]*alt="((?:(?!Gif)[^"])*)"[^>]*>/g;
        const imageMatches = message.match(imageRegex) || [];
        const ShowImages = imageMatches.slice(0, 9);
        const otherContent = message.replace(imageRegex, '').replace(/<p[^>]*>(\s*<br\s*\/?>\s*)*<\/p>/g, '');
        const parser = new DOMParser();
        const otherContentElements = parser.parseFromString(otherContent, 'text/html').body.childNodes;
        const elements = [];
        otherContentElements.forEach((element, idx) => {
            if (element.tagName !== undefined && element.tagName !== null && element.tagName !== "") {
                if (element.tagName.toLowerCase() === 'p') {
                    const anchorTags = element.getElementsByTagName('a');
                    const imgTags = element.getElementsByTagName('img');
                    const newDiv = document.createElement('div');
                    if (anchorTags.length === 0 && imgTags.length === 0) {
                        const contentHTML = element.innerHTML;
                        const modifiedHTML = contentHTML.replace(/(https?:\/\/[^\s]+)/g, '<a href="$1" target="_blank" style="cursor: pointer;">$1</a>');
                        element.innerHTML = modifiedHTML;
                    }
                    for (let i = 0; i < anchorTags.length; i++) {
                        anchorTags[i].setAttribute('target', '_blank');
                        anchorTags[i].style.cursor = 'pointer';
                        if (isFromConnectApp) {
                            newDiv.innerHTML = anchorTags[i].innerHTML;
                            newDiv.style.cursor = 'pointer';
                            newDiv.className = 'mobileURlLink';
                            newDiv.dataset.LinkTarget = anchorTags[i].href || anchorTags[i].innerHTML;
                            newDiv.style.textDecoration = "underline"
                            anchorTags[i].replaceWith(newDiv);
                        }
                    }
                }
            }

            let htmlContent = element.outerHTML === undefined ? message : element.outerHTML;
            const pattern = /<br>(&nbsp;)+/g;
            const cleanedHtml = htmlContent.replace(pattern, '');
            elements.push(<div id={messageObject.messageId} key={`other_${idx}`} dangerouslySetInnerHTML={{ __html: cleanedHtml }} />);
        });

        if (imageMatches.length > 0) {
            elements.push(
                <div className='image-wrap multiple' data-image={ShowImages.length} onClick={(e) => ChatIamgeClick(message, e)} onTouchEnd={(e) => { (isIOSMobile || (device || "").includes('iosDevice')) && e.cancelable && e.target.click() }}>
                    {ShowImages.map((imgTag, idx) => (
                        <div key={idx} className="imgBlock">
                            <img style={{ cursor: 'pointer' }} src={imgTag.match(/src="([^"]+)"/)[1]} alt={imgTag.match(/alt="([^"]+)"/) ? imgTag.match(/alt="([^"]+)"/)[1] : `Image ${idx + 1}`} />
                            {imageMatches.length > 8 && idx === 8 &&
                                <div className={idx === 8 && "moreImgText"}>
                                    {idx === 8 && imageMatches.length - 9 > 0 && <>+{imageMatches.length - 9}</>}
                                </div>
                            }
                        </div>
                    ))}
                </div>
            );
        }

        return elements;
    }

    const ChatIamgeClick = (imageMatches, event) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(imageMatches, "text/html");
        const imgTags = doc.querySelectorAll("img");
        var srcValues = [];
        imgTags.forEach(img => {
            const alt = img.getAttribute("alt")
            if (alt !== "Gif") {
                srcValues.push({ original: img.getAttribute("src"), thumbnail: img.getAttribute("src"), name: img.getAttribute("alt") });
            }
        });
        const index = srcValues.findIndex(item => item.original === event?.target?.src);
        if (index !== -1) {
            const sortedArray = srcValues.slice(index);
            srcValues = sortedArray.concat(srcValues.slice(0, index));
        }
        setGalleryImages(srcValues);
        if (isCustomerChat) {
            var jsonData = JSON.stringify({ IframName: 'chatFrame', className: 'vc-iframeGalleryOpen' }).replace(/\\\"/g, '"').replace(/"/g, "\\\"");
            window.parent.postMessage('{"eventType": "iFrameAddClass","Data": "' + jsonData + '"}', '*');
            var jsonData = JSON.stringify({ IframName: 'body', className: 'vc-iframeGalleryOpen-parentBody' }).replace(/\\\"/g, '"').replace(/"/g, "\\\"");
            window.parent.postMessage('{"eventType": "iFrameAddClass","Data": "' + jsonData + '"}', '*');
        }
        if (expandMinimizeShow === 2) {
            var jsonData = JSON.stringify({ IframName: 'chatFrame', className: 'vc-iframeGalleryOpenMini' }).replace(/\\\"/g, '"').replace(/"/g, "\\\"");
            window.parent.postMessage('{"eventType": "iFrameAddClass","Data": "' + jsonData + '"}', '*');

        }
        setImageGallery(true);
        AddCommonMethod("MobileCallBackForBack", () => { CommonMethod.Actionclick("btnCloseImageGallary") })
        if (isFromConnectApp) {
            utils.CallBackGivenToMobile(MobileCallBackAction.ImageGallary, { NativeAction: 4, photoUrlList: srcValues, customername: "", selectedPosition: 0 })
        }

    }

    const EditDeleteMessage = (messageData, action, isLastMessageIndex) => {
        if (!CheckModifyRights() && !isCustomerChat) {
            // return ReactDOM.render(
            //     <Fragment>
            //         <SuccessAlert
            //             alertType={"warning"}
            //             strMessage={"You Don't Have Rights"}
            //             displaytime={2000}
            //         />
            //     </Fragment>,
            //     document.getElementById("OpenDialog1")
            // );
            utils.ShowAlert("Restricted Access", "You have not been given access to this page. Contact your account admin or business owner to get access.", "Close");
            return;
        }
        if (chatSelection.IsBlocked === true) {
            // return ReactDOM.render(
            //     <Fragment>
            //         <SuccessAlert
            //             alertType={"warning"}
            //             strMessage={`You Don't Have a permission to Send Message`}
            //             displaytime={2000}
            //         />
            //     </Fragment>,
            //     document.getElementById("OpenDialog1")
            // );

            if (isCustomerChat) {
                return ReactDOM.render(
                    <Fragment>
                        <SuccessAlert
                            alertType={"warning"}
                            strMessage={`${ReactBusinessName} is currently not accepting messages at this time. Please try calling the business directly.`}
                            displaytime={2000}
                        />
                    </Fragment>,
                    document.getElementById("OpenDialog1")
                );
            } else {
                utils.ShowAlert("Alert", "You Don't Have a permission to Send Message", "Close");
                return;
            }
        }
        if (action === 1) { //Edit
            setshowEditEditor(messageData);
        } else if (action === 2 || action === 5) { //Delete/Undo Delete
            messageData.isDeleted = action === 2 ? true : false;
            if (!isCustomerChat && convertionData.filter((last) => last.conversationId === chatSelection.ConversationId && isLastMessageIndex !== undefined && isLastMessageIndex == true).length > 0) {
                const updatedData = convertionData.map(d =>
                    d.conversationId === chatSelection.ConversationId && isLastMessageIndex == true
                        ? { ...d, lastMessage: action === 2 ? "This message was deleted" : messageData.message }
                        : d
                );
                setConvertionData(updatedData);
            }
            setshowEditDeleteAction(null);
            setshowEditEditor(null);
            DeleteUndoMessage(messageData);
        } else if (action === 3) { //Save
            // setshowEditDeleteAction(null);
            // setshowEditEditor(null);            
            if (!isCustomerChat && convertionData.filter((last) => last.conversationId === chatSelection.ConversationId && isLastMessageIndex !== undefined && isLastMessageIndex == true).length > 0) {
                const updatedData = convertionData.map(d =>
                    d.conversationId === chatSelection.ConversationId && isLastMessageIndex == true
                        ? { ...d, lastMessage: messageData.message }
                        : d
                );
                setConvertionData(updatedData);
            }
            if (messageData.isEdited === true) {
                EditMessages(messageData);
            }
        } else if (action === 4) { //Cancel
            setshowEditDeleteAction(null);
            setshowEditEditor(null);
        }
    }

    const GroupchatIcon = (memberId) => {
        const Membicon = chatSelection.AllData.members.filter(item => item.memberIdHash === memberId);
        if (Membicon.length > 0) {
            return (
                <Fragment>
                    {Membicon[0].photo !== undefined ?
                        < img alt='avtar' className="avatar-group-member" src={Membicon[0].cdnUrl + 'Original/' + Membicon[0].photo} />
                        : <div> {utils.setLetter(Membicon[0].firstName || "", Membicon[0].lastName || "")}</div>
                    }
                </Fragment>
            )
        } else {
            return
        }
    }

    const CheckModifyRights = () => {
        if ((("Customer" === LeftOption && ModifyCustomer === false) || ("Employee" === LeftOption && ModifyEmployee === false)) && !isCustomerChat) {
            return false;
        } else {
            return true;
        }
    }
    const MobileDetailsDropdown = () => {

        var data = convertionData.find(val => val.conversationId === chatSelection.AllData.conversationId);
        var ConID = 0;
        var pin = false
        var messageId = 0;
        var read = true;
        var block = false;
        var mute = false;
        var customerHashId = "";
        if (data) {

            ConID = data.conversationId;
            pin = data.isPin
            messageId = data.messageId;
            read = data.isRead;
            block = data.isBlocked;
            mute = data.isMute;
            customerHashId = data.customerIdHash;
            return (
                <Dropdown className="mobile-contextmenu">
                    <Dropdown.Toggle variant="">
                        <i class="fa-regular fa-ellipsis-vertical"></i>
                    </Dropdown.Toggle>
                    {chatSelection.Type === LeftControlNames.Customer && <Dropdown.Menu>
                        {isAndroidIOSMobile &&
                            <Dropdown.Item href={false} onClick={(e) => {
                                // utils.CallBackGivenToMobile(MobileCallBackAction.CustomerProfile, { "customerId": data.customerId })
                                // utils.UnderDevelopment();
                                CommonMethod.Actionclick("messagesAreaGroupDetail")
                            }}> <i class="fa-solid fa-list"></i>  View Details</Dropdown.Item>
                        }
                        <Dropdown.Item href={false} onClick={(e) => { CommonMethod.PinConvertion(LoginUserIDV2, ConID, pin); pin = !pin; }}>  <i class="fa-regular fa-thumbtack"></i> {pin ? "Unpin Chat" : "Pin Chat"}</Dropdown.Item>
                        <Dropdown.Item href={false} onClick={(e) => { CommonMethod.MarkMessageUnread(ConID, LoginUserIDV2, messageId, read, false, customerHashId); }}> <i class="fa-regular fa-envelope"></i>  {read ? "Mark as Unread" : "Mark as Read"} </Dropdown.Item>
                        <Dropdown.Item href={false} onClick={(e) => { block ? CommonMethod.BlockConvertion(PopupTypes.UnBlockUser, data) : CommonMethod.BlockConvertion(PopupTypes.BlockUser, data) }}> <i class="fa-regular fa-circle-xmark"></i> {block ? "Unblock User" : "Block User"}</Dropdown.Item>
                        <Dropdown.Item href={false} onClick={(e) => { CommonMethod.RemoveChat(PopupTypes.RemoveChat, ConID); }} className='remove-chat-red'> <i class="fa-regular fa-trash"></i> Remove Chat</Dropdown.Item>
                    </Dropdown.Menu>}
                    {chatSelection.Type === LeftControlNames.Employee && chatSelection.AllData.isGroupChat === false && <Dropdown.Menu>
                        {isAndroidIOSMobile &&
                            <Dropdown.Item href={false} onClick={(e) => {
                                // utils.CallBackGivenToMobile(MobileCallBackAction.EmployeeProfile, { "employeeId": data.customerId })
                                // utils.UnderDevelopment();
                                CommonMethod.Actionclick("messagesAreaGroupDetail")
                            }}> <i class="fa-solid fa-list"></i>  View Details</Dropdown.Item>
                        }
                        <Dropdown.Item href={false} onClick={(e) => { CommonMethod.PinConvertion(LoginUserIDV2, ConID, pin); pin = !pin; }}> <i class="fa-regular fa-thumbtack"></i> {pin ? "Unpin Chat" : "Pin Chat"}</Dropdown.Item>
                        {/* <Dropdown.Item href={false} onClick={(e) => { CommonMethod.MuteNotification(LoginUserIDV2, ConID, mute ) }}>{mute ? "Unmute Notification":"Mute Notification"}</Dropdown.Item> */}
                        <Dropdown.Item href={false} onClick={(e) => { CommonMethod.MarkMessageUnread(ConID, LoginUserIDV2, messageId, read, false, customerHashId); }}> <i class="fa-regular fa-envelope"></i>  {read ? "Mark as Unread" : "Mark as Read"} </Dropdown.Item>
                        <Dropdown.Item href={false} onClick={(e) => { CommonMethod.RemoveChat(PopupTypes.RemoveChat, ConID); }}> <i class="fa-regular fa-trash"></i> Remove Chat</Dropdown.Item>
                    </Dropdown.Menu>}
                    {chatSelection.Type === LeftControlNames.Employee && chatSelection.AllData.isGroupChat === true &&
                        <Dropdown.Menu>
                            {isAndroidIOSMobile &&
                                <Dropdown.Item href={false} onClick={() => { CommonMethod.Actionclick("messagesAreaGroupDetail") }}> <i class="fa-solid fa-list"></i>  View Details</Dropdown.Item>
                            }
                            {/* <Dropdown.Item href={false} onClick={() => { CommonMethod.Actionclick("messagesAreaGroupDetail"); CommonMethod.Actionclick("divAddMember"); AddCommonMethod("MobileCallBackForBack", ()=>{ CommonMethod.Actionclick("rightMobileHeaderBack");}) }}> <i class="fa-regular fa-user-plus"></i>  Add Members</Dropdown.Item> */}
                            {/* <Dropdown.Item href={false} onClick={(e) => { CommonMethod.MuteNotification(LoginUserIDV2, ConID, mute) }}>{mute ? "Unmute Notification" : "Mute Notification"}</Dropdown.Item> */}
                            <Dropdown.Item href={false} onClick={(e) => { CommonMethod.PinConvertion(LoginUserIDV2, ConID, pin); pin = !pin; }}> <i class="fa-regular fa-thumbtack"></i>  {pin ? "Unpin Chat" : "Pin Chat"}</Dropdown.Item>
                            {CheckLoginUserIsRemovedFromChat(data) === false &&
                                <>
                                    <Dropdown.Item href={false} onClick={(e) => { CommonMethod.MarkMessageUnread(ConID, LoginUserIDV2, messageId, read, false, customerHashId); }}> <i class="fa-regular fa-envelope"></i>  {read ? "Mark as Unread" : "Mark as Read"} </Dropdown.Item>
                                    <Dropdown.Item href={false} onClick={(e) => { CommonMethod.RemoveChat(PopupTypes.LeaveChat, ConID); }}> <i class="fa-regular fa-arrow-right-from-bracket"></i> Leave Chat</Dropdown.Item>
                                </>}
                            <Dropdown.Item href={false} onClick={(e) => { CommonMethod.RemoveChat(PopupTypes.RemoveChat, ConID); }}> <i class="fa-regular fa-trash"></i> Remove Chat</Dropdown.Item>
                        </Dropdown.Menu>}
                </Dropdown>
            );
        } else {
            return <></>;
        }
    }

    const handleOutsideClick = (e) => {
        setshowEditDeleteAction(null);
    };

    const handleScrollEvent = (e) => {
        let isAtBottom = e.target.scrollTop >= e.target.scrollHeight - e.target.clientHeight - (isAndroidIOSMobile ? 5 : 30);
        let isAtTop = e.target.scrollTop === 0;
        StoreScrollHeight.current = e.target.scrollTop;
        ScrollisBottom.current = isAtBottom;
        if (isLoadedData.current === true && restrictPaging === true) {
            if (!isAtTop && chatSelection.AllData.pageIndex !== undefined && chatSelection.AllData.pageIndex !== 0
                && isAtBottom && chatSelection.AllData.pageIndex > 0) {
                // api call with chatSelection.AllData.pageIndex - 1
                chatSelection.AllData.pageIndex -= 1;
                if (chatSelection.AllData.pageIndex > 0) {
                    GetCustomerMessageConvertion(chatSelection.ConversationId, chatSelection.AllData.pageIndex);
                }
            } else if (isAtTop && restrictPaging === true && e.target.scrollHeight > e.target.clientHeight) {
                setPageIndex(pageIndex + 1);
            }
        }
        window.onScrollCloseItems();
    }

    return (
        <div className="messagesArea flex-fill">
            {!props.newChat ?
                <Fragment>
                    <div className={`messagesArea-header ${(isViewChanging && !isAndroidIOSMobile) ? " viewchange" : ""}`} style={{}}>
                        <div className="messagesArea-header_nameArea" onClick={() => {
                            if (isIpad || isIpadold || isTablet) {
                                if (rightsidePenal.current?.className) {
                                    if (rightsidePenal.current.className === "rightSlide rightSlide-open") {
                                        rightsidePenal.current.className = "rightSlide rightSlide-close"
                                    } else {
                                        rightsidePenal.current.className = "rightSlide rightSlide-open";
                                    }
                                }
                                if (isFromConnectApp && isAndroidIOSMobile === false) {
                                    CommonMethod.RightSidePanelSetHeight();
                                }
                            }
                        }}>
                            <a href={false} id='messagesAreaheaderback' className='messagesArea-header_back' onClick={() => { onClickBackButtonFromChatArea(); AddCommonMethod("MobileCallBackForBack", () => { console.log("Mobile CallBack For Back Method Clear") }); HandleHistory("back"); }}>
                                <i class="fa-solid fa-angle-left"></i>
                            </a>
                            <a className="mobileChatBack" href={false} onClick={() => ChatBoxCloseClick()}>
                                <i class="fa-regular fa-angle-left"></i>
                            </a>
                            <div className='messagesArea-header_avGroup' >
                                <div id="messagesAreaGroupDetail" onClick={() => { if (isFromConnectApp) { if (isAndroidIOSMobile) { window.DeleteChannelDetail(false, true) } CommonMethod.AddClass(".vConnectMainWrap.chatActive", "detailsActive"); CommonMethod.Actionclick('reSizeDetail'); HandleHistory('push', 'detailsActive'); AddCommonMethod("MobileCallBackForBack", () => { CommonMethod.Actionclick("rightMobileHeaderBack"); }); } }}>
                                    {!isCustomerChat && chatSelection.AllData.isGroupChat === false &&
                                        <Fragment>
                                            <Usericon URL={chatSelection.AllData.cdnUrl} Image={chatSelection.AllData.profileImage} FirstName={chatSelection.AllData.firstName} LastName={chatSelection.AllData.lastName} />
                                        </Fragment>
                                    }
                                    {chatSelection.AllData.isGroupChat === true &&
                                        <Groupicon data={chatSelection.AllData.members.filter((x) => x.isMemberRemovedFromGroup === false)} />
                                    }
                                    {isCustomerChat ?
                                        <div className="smallChatBox-middle_nameArea">
                                            {ReactBusinessImage !== "" && <div className="smallChatBox-middle_nameArea-imgWrap">
                                                <img src={ReactBusinessImage} alt="Business logo" />
                                            </div>}
                                            <div className="smallChatBox-middle_nameArea-nameWrap">
                                                {ReactBusinessName}
                                            </div>
                                        </div>

                                        :
                                        <div className="messagesArea-header_name">
                                            {chatSelection.AllData.name}
                                        </div>
                                    }
                                </div>
                            </div>

                            <div className='messagesArea-header_moreVertical'>
                                {isFromConnectApp &&
                                    <MobileDetailsDropdown />
                                }
                            </div>

                        </div>

                        {!isCustomerChat && <div className="messagesArea-header_btnArea">
                            <div className="rightAreaSlideTrigger">
                                <BtnRightsidepenal />
                            </div>
                            {isFromConnectApp === false &&
                                <div className="extendButton minimize" onClick={() => ExpandMinimizeButtonClick(expandMinimizeShow === 0 ? 2 : expandMinimizeShow + 1)}>
                                    <i class="fa-solid fa-arrow-down-left-and-arrow-up-right-to-center"></i>
                                </div>
                            }
                        </div>}

                    </div>

                    {isCustomerChat ?
                        (Object.entries(messageConvertions).length === 0 && chatSelectionID !== "") && <StenCiledConvertions isMobile={isAndroidIOSMobile} />
                        :
                        Object.entries(messageConvertions).length === 0 && <StenCiledConvertions isMobile={isAndroidIOSMobile} />
                    }
                    <div ref={middleArea} onScroll={(e) => { handleScrollEvent(e) }} className={`messagesArea-middle ${isViewChanging ? " viewchange" : ""}`} style={{ display: isViewChanging ? "none" : "block" }}>
                        <div className='chat-middle-box'>
                            <Fragment>
                                {Object.entries(messageConvertions).map(([date, messages]) => (
                                    <div key={date}>
                                        <div className="inChatmsg">
                                            {messages.every(message => message.isDeleted === true) === true && isCustomerChat ? messages.filter(message => message.isMessageFromCustomer).length > 0 && DateFormateFomChat(date, false, countryID) : messages.every(message => message.isDeleted === true && message.isMessageFromCustomer) ? <></> : isCustomerChat && messages.every(message => message.isNote) === true ? <></> : DateFormateFomChat(date, false, countryID)}
                                        </div>
                                        {messages.map((message, index) => (
                                            <Fragment>
                                                {message.isGroupUpdateMessage ?
                                                    <div className="inChatmsg personBlockText">
                                                        {message.message}
                                                    </div>
                                                    :
                                                    <Fragment>
                                                        {(LeftControlNames.Customer === LeftOption ? isCustomerChat ? message.isMessageFromCustomer === false : message.isMessageFromCustomer === true : message.sentByHash !== props.LoginUserIdHash) ?
                                                            <Fragment>
                                                                {!isCustomerChat && message.isDeleted === false &&
                                                                    <div className="recived messagesBox">
                                                                        <div className="avatar avatar-group avatar-sm" data-avatar="1">
                                                                            <div className="avatar-group-img-wrap">
                                                                                {message.senderPhoto !== undefined && message.senderPhoto !== null && message.senderPhoto !== "" ?
                                                                                    <img alt='avtar' className="avatar-group-member" src={message.senderCdnURL + 'Original/' + message.senderPhoto} />
                                                                                    : <div> {utils.setLetter(message.senderFirstName || "", message.senderLastName || "")}</div>}
                                                                            </div>
                                                                        </div>
                                                                        <div className="messagesBox-block">
                                                                            <div className="name">
                                                                                {message.senderFirstName + " " + message.senderLastName}
                                                                            </div>
                                                                            <div className={"messages"}
                                                                                onClick={(e) => {
                                                                                    if (isFromConnectApp && e.target.tagName !== 'IMG') {
                                                                                        if (e.target.className.includes('mobileURlLink')) {
                                                                                            utils.CallBackGivenToMobile(MobileCallBackAction.RedirectToLink, (e.target.dataset?.LinkTarget) ? e.target.dataset?.LinkTarget : e.target.innerText);
                                                                                        }
                                                                                    }
                                                                                }}
                                                                            >
                                                                                {ShowDesignMessage(message.message, message)}
                                                                            </div>
                                                                            <div className="time">{ConvertUtcToLocal(message.messageDate)}</div>
                                                                        </div>
                                                                    </div>
                                                                }
                                                                {isCustomerChat && message.isNote === false && message.isDeleted === false &&
                                                                    <div className="recived messagesBox">
                                                                        <div className="messagesBox-block">
                                                                            <div className="name">
                                                                                {ReactBusinessName}
                                                                            </div>
                                                                            <div className={"messages"}>
                                                                                {ShowDesignMessage(message.message, message)}
                                                                            </div>
                                                                            <div className="time">{ConvertUtcToLocal(message.messageDate)}</div>
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </Fragment>
                                                            :
                                                            <Fragment>
                                                                {message.isDeleted === false &&
                                                                    <div className={`sent messagesBox${showEditEditor !== null && showEditEditor.messageId === message.messageId ? " messagesEditWrap" : ""} `}>
                                                                        <div className="messagesBox-block">
                                                                            {!isCustomerChat &&
                                                                                <div className="name">
                                                                                    {message.senderFirstName + " " + message.senderLastName}{isMultilocationBusiness ? " - " + ReactBusinessName : ""}
                                                                                </div>
                                                                            }
                                                                            <div
                                                                                id={message.messageId}
                                                                                className={message.isNote ? "messages message-note-block" : "messages"}
                                                                                onClick={(e) => {
                                                                                    if (isFromConnectApp && e.target.tagName !== 'IMG') {
                                                                                        if (e.target.className.includes('mobileURlLink')) {
                                                                                            utils.CallBackGivenToMobile(MobileCallBackAction.RedirectToLink, (e.target.dataset?.LinkTarget) ? e.target.dataset?.LinkTarget : e.target.innerText);
                                                                                        } else if (message.sentByHash === LoginUserIdHash) {
                                                                                            if (showEditEditor === null || (showEditEditor.messageId !== message.messageId)) {
                                                                                                setshowEditDeleteAction(message.messageId);
                                                                                            }
                                                                                        }
                                                                                    } else if (!isFromConnectApp) {
                                                                                        if (message.sentByHash === LoginUserIdHash) {
                                                                                            if (showEditEditor === null || (showEditEditor.messageId !== message.messageId)) {
                                                                                                setshowEditDeleteAction(message.messageId);
                                                                                            }
                                                                                        }
                                                                                    }

                                                                                    // if (isAndroidIOSMobile === false) {
                                                                                    //     if (message.sentByHash === LoginUserIdHash) {
                                                                                    //         if (showEditEditor === null || (showEditEditor.messageId !== message.messageId)) {
                                                                                    //             setshowEditDeleteAction(message.messageId);
                                                                                    //         }
                                                                                    //     }
                                                                                    // }
                                                                                }}
                                                                                onTouchStart={(e) => {
                                                                                    if (isAndroidIOSMobile && e.target.tagName === 'IMG') {
                                                                                        setHoldFuctiontimer(setTimeout(() => {
                                                                                            // Call function one for touch-and-hold action
                                                                                            if (message.sentByHash === LoginUserIdHash) {
                                                                                                if (showEditEditor === null || (showEditEditor.messageId !== message.messageId)) {
                                                                                                    setshowEditDeleteAction(message.messageId);
                                                                                                    CommonMethod.RemoveSelectionForMobile(isIOSMobile);
                                                                                                }
                                                                                            }
                                                                                            setHoldFuctiontimer(null); // Reset timer after touch-and-hold
                                                                                        }, 250));
                                                                                    }
                                                                                }}
                                                                                onTouchEnd={(e) => {
                                                                                    if (isAndroidIOSMobile && e.target.tagName === 'IMG') {
                                                                                        clearTimeout(holdFuctiontimer);
                                                                                        if (holdFuctiontimer) {
                                                                                            // Call function two for tap action if timer was not cleared
                                                                                            if (e.target.className.includes('mobileURlLink')) {
                                                                                                utils.CallBackGivenToMobile(MobileCallBackAction.RedirectToLink, (e.target.dataset?.LinkTarget) ? e.target.dataset?.LinkTarget : e.target.innerText);
                                                                                            }
                                                                                            else if (e.target.tagName === 'IMG') { console.log("Image"); }
                                                                                            else if (message.sentByHash === LoginUserIdHash) {
                                                                                                if (showEditEditor === null || (showEditEditor.messageId !== message.messageId)) {
                                                                                                    setshowEditDeleteAction(message.messageId);

                                                                                                }
                                                                                            }
                                                                                        }

                                                                                    }
                                                                                }}
                                                                                onTouchCancel={() => {
                                                                                    clearTimeout(holdFuctiontimer)
                                                                                    setHoldFuctiontimer(null);
                                                                                }}

                                                                                onMouseEnter={() => {
                                                                                    if (isAndroidIOSMobile === false && message.sentByHash === LoginUserIdHash) {
                                                                                        if (showEditEditor === null || (showEditEditor.messageId !== message.messageId)) {
                                                                                            setshowEditDeleteAction(message.messageId);
                                                                                        }
                                                                                    }
                                                                                }}
                                                                                onMouseLeave={() => {
                                                                                    setshowEditDeleteAction(null);
                                                                                }}>
                                                                                {chatSelection.AllData.isLoggedInUserRemovedFromGroup === false && chatSelection.IsBlocked === false && showEditDeleteAction === message.messageId && <div className='editControlWrap'>
                                                                                    <a href={false} onClick={(e) => { e.stopPropagation(); setshowEditDeleteAction(null); EditDeleteMessage(message, 1); }} onTouchEnd={(e) => { isIOSMobile && e.cancelable && e.target.click() }} className='editControl'>
                                                                                        <i class="fa-solid fa-pencil"></i> <span className='editControlText'>{message.isNote ? "Edit Note" : "Edit Text"} </span>
                                                                                    </a>
                                                                                    <a href={false} onClick={() => EditDeleteMessage(message, 2, index === messages.length - 1 ? true : false)} onTouchEnd={(e) => { isIOSMobile && e.cancelable && e.target.click() }} className='editControl'>
                                                                                        <i class="fa-regular fa-trash"></i> <span className='editControlText'>{message.isNote ? "Delete Note" : "Delete Text"}</span>
                                                                                    </a>
                                                                                </div>}
                                                                                {showEditEditor !== null && showEditEditor.messageId === message.messageId ?
                                                                                    (<EditInput {...props} ckeditortype={2} callBackChatConversation={window.callBackChatConversation} isNewChat={isNewChat} convertionData={convertionData} LeftOption={LeftOption} messageDetails={message} EditDeleteMessage={EditDeleteMessage} expandMinimizeShow={expandMinimizeShow} isLastMessageIndex={index === messages.length - 1 ? true : false} ManageCustomerViewRights={ManageCustomerViewRights} ManageCustomerModifyRights={ManageCustomerModifyRights} />)
                                                                                    : ShowDesignMessage(message.message, message)}
                                                                            </div>
                                                                            <div className="time">
                                                                                {ConvertUtcToLocal(message.messageDate)}
                                                                                <span class="chechIcon"></span>
                                                                            </div>
                                                                        </div>

                                                                        {!isCustomerChat && <div className="avatar avatar-sm" data-avatar="1">
                                                                            <div className="avatar-group-img-wrap">
                                                                                {message.sentByHash === LoginUserIdHash ?
                                                                                    ReactProfileImage !== undefined && ReactProfileImage !== null && ReactProfileImage !== "" ?
                                                                                        <img alt='avtar' src={ReactCdnUrl + 'Original/' + ReactProfileImage} className='avatar-group-member' />
                                                                                        : <div> {utils.setLetter(ReactFirstName || "", ReactLastName || "")}</div>

                                                                                    :
                                                                                    message.senderPhoto !== undefined && message.senderPhoto !== null && message.senderPhoto !== "" ?
                                                                                        <img alt='avtar' className="avatar-group-member" src={message.senderCdnURL + 'Original/' + message.senderPhoto} />
                                                                                        : <div> {utils.setLetter(message.senderFirstName || "", message.senderLastName || "")}</div>

                                                                                }
                                                                            </div>
                                                                        </div>}
                                                                    </div>
                                                                }
                                                                {message.isDeleted === true && message.sentByHash === LoginUserIdHash &&
                                                                    <div className="chat-deletedWrap">
                                                                        <div className="chat-deleted">
                                                                            This message has been deleted. <a className='chat-deleted_undo' href={false} onClick={() => EditDeleteMessage(message, 5, index === messages.length - 1 ? true : false)}>Undo</a>
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </Fragment>
                                                        }
                                                    </Fragment>
                                                }
                                            </Fragment>
                                        )
                                        )}
                                    </div>
                                ))}
                            </Fragment>
                        </div>

                    </div >

                    {!isCustomerChat && <div className="smallChatBox-footer">
                        <nav className="smallChatBox-footer_actions">
                            <a href={false}>
                                <img src="https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/attachmentBlue.svg" alt="" />
                            </a>
                            <a href={false}>
                                <img src="https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/gifBlue.svg" alt="" />
                            </a>
                            <a href={false}>
                                <img src="https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/urlBlue.svg" alt="" />
                            </a>
                            <a href={false}>
                                <img src="https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/noteIcon.svg" alt="" />
                            </a>
                        </nav>

                        <div className="smallChatBox-footer_fieldWrap">
                            <input type="text" className="inputField" placeholder="Enter a message." />
                            <a href={false} className="smileIcon">
                                <img src="https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/smileBlue.svg" alt="" />
                            </a>
                        </div>
                    </div>}

                    {
                        (!isCustomerChat ? (expandMinimizeShow === 0 || chatAreaShow) : chatAreaShow) &&
                        <div className="messagesArea-footer">
                            <div className={(isAndroidIOSTabIpad() && isCustomerChat === false) ? 'typeChatWrap' : isCustomerChat ? (chatAreaShow ? "typeChatWrap collapsibleChat" : "typeChatWrap") : `typeChatWrap${chatAreaShow && expandMinimizeShow !== 0 ? " collapsibleChat" : ""} `}>
                                {/* Use This class for mobile devices
                        <div className={`typeChatWrap${isCustomerChat || chatAreaShow ? " mobileEditer active" : ""} `}>
                    */}
                                <EditInput {...props} callBackChatConversation={window.callBackChatConversation} isNewChat={isNewChat} convertionData={convertionData} LeftOption={LeftOption} expandMinimizeShow={expandMinimizeShow} ManageCustomerViewRights={ManageCustomerViewRights} ManageCustomerModifyRights={ManageCustomerModifyRights} />
                            </div>
                        </div>
                    }
                    {
                        imageGallery && !isFromConnectApp &&
                        <ImageGallery {...props} srcValues={galleryImages} setImageGalarry={setImageGallery} isCustomerChat={isCustomerChat} expandMinimizeShow={expandMinimizeShow} />
                    }
                </Fragment>
                :
                LeftOption === LeftControlNames.Employee ?
                    <div className="">
                        <div className='employeeSelectWrap'>
                            <MultiGroupSelectEmployee
                                className=""
                                classNamePrefix="vg-select"
                                aria-label="Default select example"
                                options={props.ServiceproviderasperMultilocation}
                                onChange={(serviceprovider, selectednew) => {
                                    props.serviceProvider(serviceprovider, selectednew);
                                }}
                                onChangeSpName={(SpName) => {
                                    props.setserviceproviderName(SpName);
                                }}
                                value={props.providervalue}
                                isMulti
                                ControlID="ddlEmployee"
                                searchInputPlaceholder={"Search"}
                                LoginUserIdHash={LoginUserIdHash}
                                placeholder="Select an teammates"
                                isandroidiospro={isAndroidIOSMobile}
                                isFromConnectApp={isFromConnectApp}
                                isNewChat={isNewChat}
                            />

                        </div>
                        <div className='newchatEditerWrap'>
                            <EditInput {...props} callBackChatConversation={window.callBackChatConversation} isNewChat={isNewChat} convertionData={convertionData} LeftOption={LeftOption} expandMinimizeShow={expandMinimizeShow} ManageCustomerViewRights={ManageCustomerViewRights} ManageCustomerModifyRights={ManageCustomerModifyRights} />
                        </div>
                    </div> :
                    <div className="">
                        <div className='employeeSelectWrap'>
                            <MerchantsSingleSelecttwo
                                ControlID="Customer"
                                placeholder="Select a customer"
                                MerchantID={merchantId}
                                OnChangeCustomer={props.OnChangeCustomer}
                                MultiCustomersId={"ddlSelectCustomer11"}
                                ManageCustomerViewRights={ManageCustomerViewRights}
                                isandroidiospro={isAndroidIOSMobile}
                                isNewChat={isNewChat}
                            />

                        </div>
                        <div className='newchatEditerWrap'>
                            <EditInput {...props} callBackChatConversation={window.callBackChatConversation} isNewChat={isNewChat} convertionData={convertionData} LeftOption={LeftOption} customerData={customerData} expandMinimizeShow={expandMinimizeShow} ManageCustomerViewRights={ManageCustomerViewRights} ManageCustomerModifyRights={ManageCustomerModifyRights} />
                        </div>
                    </div>
            }
        </div >

        /* This is for membership and gift card design Do not remove this
    <div className="sent messagesBox">
     
    <div className="messagesBox-block">
    <div className="name">
    xyg
    </div>
    <div className="messages vcCard">
    <div className='vcCard-title'>
        <div className='vcCard-title_icon'>
            <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.5625 2.75C16.5879 2.75 17.4375 3.59961 17.4375 4.625V14C17.4375 15.0547 16.5879 15.875 15.5625 15.875H2.4375C1.38281 15.875 0.5625 15.0547 0.5625 14V4.625C0.5625 3.59961 1.38281 2.75 2.4375 2.75H6.65625V1.8125C6.65625 1.31445 7.06641 0.875 7.59375 0.875H10.4062C10.9043 0.875 11.3438 1.31445 11.3438 1.8125V2.75H15.5625ZM8.0625 2.28125V4.15625H9.9375V2.28125H8.0625ZM16.0312 14V4.625C16.0312 4.39062 15.7969 4.15625 15.5625 4.15625H11.3438V5.5625H6.65625V4.15625H2.4375C2.17383 4.15625 1.96875 4.39062 1.96875 4.625V14C1.96875 14.2637 2.17383 14.4688 2.4375 14.4688H5.71875C5.71875 14.4395 5.71875 14.4102 5.71875 14.3809V13.8242C5.71875 12.8867 6.59766 12.125 7.68164 12.125C8.00391 12.125 8.20898 12.3594 9 12.3594C9.76172 12.3594 9.99609 12.125 10.2891 12.125C11.373 12.125 12.2812 12.8867 12.2812 13.8242V14.3809C12.2812 14.4102 12.252 14.4395 12.252 14.4688H15.5625C15.7969 14.4688 16.0312 14.2637 16.0312 14ZM9 7.4375C10.0254 7.4375 10.875 8.28711 10.875 9.3125C10.875 10.3672 10.0254 11.1875 9 11.1875C7.94531 11.1875 7.125 10.3672 7.125 9.3125C7.125 8.28711 7.94531 7.4375 9 7.4375Z" fill="#333333"/>
            </svg>
        </div>
        <div className='vcCard-title_name'>Membershipe Name</div>
    </div>
    <a href="{false}" className='vcCard-button'>Shop for Membership</a>
    </div>
    <div className="time">
    {moment(message.messageDate).format("hh:mm A")}
    <span class="chechIcon"></span>
    </div>
    </div> 
     
    <div className="avatar avatar-sm" data-avatar="1">
    <div className="avatar-group-img-wrap">
    {ReactProfileImage !== undefined ?
        <img alt='avtar' src={ReactCdnUrl + 'Original/' + ReactProfileImage} className='avatar-group-member' />
        : <div> {utils.setLetter(ReactFirstName || "", ReactLastName || "")}</div>
    }
    </div>
    </div>
    </div>
    */
    );
}

export default ChatArea;